<template xmlns="http://www.w3.org/1999/html">
  <div>
    <el-row>
      <el-col>
        <el-form :inline='true'>
          <el-form-item label="" >
            <el-select style="width:110px" placeholder="省区" v-model="filters.provinceCode" filterable clearable>
              <el-option v-for="item in provinceSearchOptions" :key="item.id" :label="item.name"
                         :value="item.code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-select placeholder="验收状态" style="width:110px" v-model="filters.expertType" clearable>
              <el-option v-for="item in expertOptions" :key="item.id" :label="item.value"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-date-picker style="width:180px" @change="changePicker" v-model="pickerData" type="daterange" range-separator="至"
                            start-placeholder="验收" value-format="yyyy-MM-dd HH:mm:ss" end-placeholder="开始">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="">
<!--            <el-autocomplete style="width:160px"  value-key="name" v-model="filters.name"-->
<!--                             @input="(e) => (filters.name = utils.validForbid(e))" placeholder="请输入工程名称"-->
<!--                             @select="handleSelect"-->
<!--                             :fetch-suggestions="querySearchAsync"></el-autocomplete>-->
            <el-input style="width:160px" placeholder="请输入工程名称" clearable v-model="filters.name"></el-input>
          </el-form-item>

          <el-form-item>
            <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <el-table id="exportTab" :data="tableData" border style="width: 100%" v-loading="listLoading"
              ref="multipleTable" :highlight-current-row='true'  @current-change='currentChange'>
      <el-table-column type='index' label="序号" width='60' align="center" :index="indexMethod"></el-table-column>
<!--      <el-table-column prop="userName" align="left" label="姓名">-->
<!--        <template #default="scope">-->
<!--          <div style="text-align: left;">-->
<!--            <el-button type="text" @click="getProject(scope.row)">-->
<!--              <span style="text-align: left;" v-html="formatUserName(scope.row.userName)"></span>-->
<!--            </el-button>-->
<!--          </div>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column prop="provinceCodeValue" label="省" align="center" ></el-table-column>
      <el-table-column prop="areaCode" label="区（县）" align="center" ></el-table-column>
      <el-table-column prop="projectName" label="工程名称" align="center" >
        <template #default="scope">
          <div style="text-align: center;">
            <el-button type="text" @click="projectDetailCheck(scope.row)">
              {{scope.row.projectName}}
            </el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="projectAddress" label="工程地址" align="center" ></el-table-column>
      <el-table-column prop="ddIdProjectType" label="工程类别" align="center" >
        <template #default="scope">
          <el-tag v-if="scope.row.ddIdProjectType == 31">普通项目</el-tag>
          <el-tag v-if="scope.row.ddIdProjectType == 33" type="danger">改造项目</el-tag>
          <el-tag v-if="scope.row.ddIdProjectType == 279" type="success">装修项目</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="acceptanceUserList" label="验收人员" align="center" >
        <template #default="scope">
          {{scope.row.acceptanceUserList?scope.row.acceptanceUserList.join('，'):'待分配'}}
        </template>
      </el-table-column>
      <el-table-column prop="ddIdProjectStatus" label="验收状态" align="center">
        <template #default="scope">
          <el-tag v-if="scope.row.ddIdProjectStatus == 254" type="warning">未开始</el-tag>
          <el-tag v-if="scope.row.ddIdProjectStatus == 255" >验收中</el-tag>
          <el-tag v-if="scope.row.ddIdProjectStatus == 256" type="success">完成</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="userSubmitOpinionNum" label="现场评定清单" align="center">
        <template #default="scope">
          <div style="text-align: center;">
            <el-button type="text" @click="checkListDialog(scope.row)">
              {{'查看'}}
            </el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="userSubmitOpinionNum" label="现场评定抽选检查" align="center">
        <template #default="scope">
          <div style="text-align: center;">
            <el-button type="text" @click="checkCheckingDialog">
              {{'查看'}}
            </el-button>
<!--            <el-button type="text" @click="">-->
<!--              {{'下载'}}-->
<!--            </el-button>-->
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="userSubmitOpinionNum" label="整改报告" align="center">
        <template #default="scope">
          <div style="text-align: center;">
            <el-button type="text" @click="reportChecking(scope.row)">
              {{'查看'}}
            </el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="userSubmitOpinionNum" label="验收评定报告" align="center">
        <template #default="scope">
          <div style="text-align: center;">
            <el-button type="text" @click="reportCheck()">
              {{'查看'}}
            </el-button>
<!--            <el-button type="text" @click="">-->
<!--              {{'下载'}}-->
<!--            </el-button>-->
          </div>
        </template>
      </el-table-column>
<!--      <el-table-column prop="opinionNumsRate" label="项目阶段 " align="center" >-->
<!--        <template #default="scope">-->
<!--          <div style="text-align: center;">-->
<!--            <el-button type="text" @click="stageProjectDetail">-->
<!--              {{'查看'}}-->
<!--            </el-button>-->
<!--          </div>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column prop="firstDate" label="验收时间" align="center" ></el-table-column>
    </el-table>
    <!--底部工具条-->
    <el-col :span="24" class="pageBar">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pages.pageIndex"
          :page-sizes="pages.pageArr"
          :page-size="pages.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="dataCount">
      </el-pagination>
    </el-col>
    <!--  创建工程项目  -->
    <el-dialog title="添加验收工程"
               :visible.sync="dialog.createDialog.show"
               v-model="dialog.createDialog.show"
               :close-on-click-modal="false"
               :width="'60%'">
      <el-form :model="addAcceptanceForm" :rules="addAcceptanceFormRules" ref="addAcceptanceForm" label-width="150px" class="demo-ruleForm">
        <el-form-item label="所属住建局：" prop="provinceCode">
          <el-select v-model="addAcceptanceForm.areaCode" placeholder="请先选择该地区的住建局"
                     @change="changeSelectArea" filterable clearable style=" width:60%;">
            <el-option
                v-for="item in companyIdList"
                :key="item.id"
                :label="item.customerName"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工程名称：" prop="projectName">
          <el-select v-model="addAcceptanceForm.projectName" placeholder="请选择工程名称"
                     filterable clearable style=" width:60%;"
                     allow-create
                     default-first-option>
            <el-option
                v-for="(item,index) in changeProjectList"
                :key="index"
                :label="item.projectName"
                :value="item.projectName"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工程类别：" prop="ddIdProjectType">
          <el-radio-group v-model="addAcceptanceForm.ddIdProjectType">
            <el-radio :label="31">新建</el-radio>
            <el-radio :label="33">改造</el-radio>
            <el-radio :label="279">装修</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="工程地址：" prop="address">
          <el-input style="width: 60%" v-model="addAcceptanceForm.address"></el-input>
        </el-form-item>
        <el-form-item label="项目申请单位：" prop="projectCompany">
          <el-input style="width: 60%" v-model="addAcceptanceForm.projectCompany"></el-input>
        </el-form-item>
        <el-form-item label="验收时间：" prop="firstDate">
          <template #default="scope">
            <div class="block">
              <el-date-picker
                  v-model="addAcceptanceForm.firstDate"
                  type="date"
                  placeholder="选择日期"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd">
              </el-date-picker>
            </div>
          </template>
        </el-form-item>
        <el-form-item label="通知：">
          <el-radio v-model="addAcceptanceForm.notify" :label="1">企业微信通知</el-radio>
        </el-form-item>
        <el-form-item label="资料上传：">
          <el-upload
              class="upload-demo"
              ref="uploadFileR"
              :action="uploadFile"
              :headers='headers'
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :on-change="handleChange"
              :on-success="handle_success"
              :file-list="fileList"
              list-type="picture"
              :auto-upload="true"
              style="width: 60% /* 设定宽度 */"
          >
            <el-button slot="trigger" size="small" type="primary">选取上传文件</el-button>
<!--            <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">先上传到服务器，再保存</el-button>-->
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="dialog.createDialog.show = false">取消</el-button>
        <el-button type="primary" @click="addCreate" >保存</el-button>
      </div>
    </el-dialog>
    <!--  编辑工程项目  -->
    <el-dialog title="编辑验收工程"
               :visible.sync="dialog.createDialog.edit"
               v-model="dialog.createDialog.edit"
               :close-on-click-modal="false"
               :width="'60%'">
      <el-form :model="editAcceptanceForm" :rules="addAcceptanceFormRules" ref="addAcceptanceForm" label-width="150px" class="demo-ruleForm">
        <el-form-item label="工程名称：" prop="projectName">
          <el-input disabled style="width: 60%" v-model="editAcceptanceForm.projectName"></el-input>
        </el-form-item>
        <el-form-item label="工程类别：" prop="ddIdProjectType">
          <el-radio-group v-model="editAcceptanceForm.ddIdProjectType">
            <el-radio disabled :label="31">新建</el-radio>
            <el-radio disabled :label="33">改造</el-radio>
            <el-radio disabled :label="279">装修</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="工程地址：" prop="address">
          <el-input disabled style="width: 60%" v-model="editAcceptanceForm.address"></el-input>
        </el-form-item>
        <el-form-item label="项目申请单位：" prop="projectCompany">
          <el-input disabled style="width: 60%" v-model="editAcceptanceForm.projectCompany"></el-input>
        </el-form-item>
        <el-form-item label="验收时间：" prop="firstDate">
          <el-input disabled style="width: 60%" v-model="editAcceptanceForm.firstDate"></el-input>
        </el-form-item>
        <el-form-item label="通知：">
          <el-radio disabled v-model="addAcceptanceForm.notify" :label="1">企业微信通知</el-radio>
        </el-form-item>
        <el-form-item label="资料上传：">
          <el-upload
              class="upload-demo"
              :action="uploadFile"
              :headers='headers'
              :on-preview="handlePreviewIsedit"
              :on-remove="(file,lists)=>handleRemoveIsedit(file,lists,0)"
              :on-change="(file,filelists)=>handleChangeEdit(file,filelists,0)"
              :on-success="handle_success_edit"
              :file-list="editQuestionForm.contentList[0].fileList"
              :auto-upload="true"
              list-type="picture"
              style="width: 60% /* 设定宽度 */"
              accept="docx,jpg,png,rar,pdf,word,"
          >
            <el-button slot="trigger" size="small" type="primary">选取上传文件</el-button>
            <!--              <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload1(index)">先上传到服务器，再保存</el-button>-->
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="dialog.createDialog.edit = false">取消</el-button>
        <el-button type="primary" @click="editCreate" >保存</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible" :width="'80%'">
      <!--      <template >-->
      <!--        <img width="100%" :src="dialogImageUrl" alt="Image"/>-->
      <!--      </template>-->
      <template >
        <!-- 这里显示 PDF 内容，你需要使用对应的方式来显示 PDF，例如使用 <embed> 或者 <iframe> 标签 -->
        <!--        <embed :src="dialogImageUrl" type="application/pdf" width="100%" />-->
        <!-- 或者使用 iframe 标签 -->
        <iframe :src="dialogImageUrl" width="100%"  :style="{ height: '65vh' }"></iframe>
      </template>
    </el-dialog>
    <!-- 验收任务分工   -->
    <el-dialog title="验收任务分工"
            :visible.sync="dialog.acceptanceDialog.show"
            v-model="dialog.acceptanceDialog.show"
            :close-on-click-modal="false"
            :width="'40%'">
      <el-form ref="form" :model="addAcceptanceTask" label-width="120px">
        <el-form-item label="验收人员：">
          <el-select v-model="addAcceptanceTask.person" multiple placeholder="请选择">
            <el-option
                v-for="item in acceptanceTaskList"
                :key="item.value"
                :label="item.userName"
                :value="item.userInfoId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="通知：" >
          <el-radio v-model="taskRadio" :label="1">企业微信通知</el-radio>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="dialog.acceptanceDialog.show = false">取消</el-button>
        <el-button type="primary" @click="addTaskPerson" >确认</el-button>
      </div>
    </el-dialog>
    <!--  添加现场评定清单  -->
    <el-dialog title="现场评定清单"
               :visible.sync="dialog.addListDialog.show"
               v-model="dialog.addListDialog.show"
               :close-on-click-modal="false"
               :width="'50%'">
      <el-form label-width="100px" >
        <el-form-item label="检查项目：" prop="region">
          <table style="width: 100%;margin-bottom: 40px">
            <thead>
            <tr>
              <th colspan="5"  style="text-align: center;">现场评定抽选检查</th>
            </tr>
            <tr>
              <th style="text-align: center;" >工程名称</th>
              <th colspan="4"><p>{{ needData.projectName }}</p></th>
            </tr>
            <tr>
              <th style="text-align: center;">抽选部分</th>
              <th colspan="2" style="text-align: center;">检查项目</th>
              <th colspan="2" style="text-align: center;">评定结论</th>
            </tr>
            </thead>
            <tbody>
            <!-- Sample data, replace this with your actual data loop -->
            <tr v-for="(rowData, index) in checkTypeList" :key="rowData.id">
              <td></td>
              <td colspan="2"  style="text-align: center;">{{ rowData.name }}</td>
              <td colspan="2"  style="text-align: center;">
                <el-radio-group v-model="rowData.optionValue">
<!--                  <el-radio :disabled="rowData.optionValue===1" :label="1">不合格</el-radio>-->
                  <el-radio  :label="1">不合格</el-radio>
                  <el-radio  :label="2">合格</el-radio>
                </el-radio-group>
              </td>
            </tr>
            <!-- End of sample data loop -->
            </tbody>

          </table>
        </el-form-item>
      </el-form>
      <div style="width: 90%;margin-left: 40px;margin-top: 25px;">

        <el-row style="margin-top: 20px ;text-align: center">
          <el-button type="primary" size="small" @click="nextStep">下一步</el-button>
        </el-row>
      </div>
    </el-dialog>
        <!-- 下一步   -->
    <el-dialog title="现场评定清单"
               :visible.sync="dialog.addListDialog.nextStep"
               v-model="dialog.addListDialog.nextStep"
               :close-on-click-modal="false"
               :width="'50%'">
      <el-form label-width="100px" class="">
        <el-button v-if="checkDialog" type="text" size='small' @click="returnStep">返回上一步</el-button>
        <el-card v-if="checkDialog" style="font-size: 16px;margin-bottom: 15px">
        <el-form-item v-for="(value,index) in checkProjectName" :key="value.id" >
          <div style="">
            <span style="justify-content: left">
              {{(index+1)+'.'+'检查项目：'+value.name}}
              <el-button type="text" size='mini' @click="addQuestionList(value)">添加问题</el-button>
            </span>
          </div>
        </el-form-item>
        </el-card>
        <div style="height: 400px; overflow-y: auto;">
        <el-form-item style="width: 98% ;" v-for="(value,index) in opinionList " :key = 'index'>
            <el-card >
              <div>
                {{index+1}}、检查项目：{{value.mainCheckTypeValue}}/{{checkTypeList.find(item=>item.id===value.mainCheckType).subCheckTypeList.find(ite=>ite.id ===value.subCheckType).name}}
              </div>
              <div>
                {{value.confirmProblem}}
              </div>
              <div>
                违反规定条款：{{value.drStandardItemDescribe}}
              </div>
              <div>
                现场照片：
                <div v-if="value.fileList" class="demo-image__preview">
                  <el-image
                      style="width: 100px; height: 100px"
                      :src="value.fileList[0]?value.fileList[0].fileUrl:undefined"
                      :preview-src-list="value.fileList.map(item=>item.fileUrl)">
                    <div slot="error" class="image-slot">
                      暂无图片
                    </div>
                  </el-image>
                </div>

              </div>
              <span v-if="checkDialog" style="display: flex; justify-content: flex-end;">
                <i class="el-icon-edit-outline" style="font-size: 22px;margin-right: 10px" @click="confirmAddListEditDialog(value)"></i>
                <i class="el-icon-delete" style="font-size: 22px" @click="delOpinionCard(value)"></i>
              </span>
            </el-card>
          </el-form-item>
        </div>

      </el-form>

      <div v-if="checkDialog" slot="footer" class="dialog-footer">
        <el-button type="primary"  @click="confirmList">确认生成清单</el-button>
      </div>
    </el-dialog>
        <!--确认生成清单    -->
    <el-dialog title="现场评定清单"
               :visible.sync="dialog.addListDialog.addOpinion"
               v-model="dialog.addListDialog.addOpinion"
               :close-on-click-modal="false"
               :width="'50%'">
      <el-form :model="addListOpinion" label-width="120px">
<!--        <el-form-item label="报告编号：">-->
<!--          <el-input v-model="addListOpinion.customReportNo" style="width: 90%"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="项目备注：">-->
<!--          <el-input v-model="addListOpinion.projectNotes" type="textarea" style="width: 90%"> </el-input>-->
<!--        </el-form-item>-->
      </el-form>
      <div style="margin: 5px auto;text-align: center;">
        <el-button @click="addOpinionClosed" >{{'取消'}}</el-button>
        <el-button type="primary" @click="addOpinionDialog" >{{'确认'}}</el-button>
      </div>
    </el-dialog>
            <!--    添加问题    -->
    <el-dialog title=""
               :visible.sync="dialog.addListDialog.addQuestion"
               v-model="dialog.addListDialog.addQuestion"
               :close-on-click-modal="false"
               :width="'50%'">
      <el-card  v-for="(list, index) in addQuestionForm.contentList" :key="index">
        <el-form :model="addQuestionForm.contentList[0]" label-position="top"  ref="" label-width="120px" class="">
          <el-form-item label="问题" >
            <el-select style="width: 100%;" v-model="addQuestionForm.contentList[0].question" placeholder="请选择问题" filterable clearable>
              <el-option
                  v-for="item in subCheckTypeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="问题表述" >
            <el-button type="primary" size="mini" @click="articlesDialog(index)" style="margin: 0 0 15px 0;" >{{'添加条文库问题'}}</el-button>
            <el-input type="textarea" v-model="addQuestionForm.contentList[0].problemStatement" :placeholder="addQuestionForm.contentList[index].problemStatement1" ></el-input>
          </el-form-item>
          <el-form-item label="违反规范条款" >
            <el-input v-model="addQuestionForm.contentList[0].violation" disabled></el-input>
          </el-form-item>
          <el-form-item label="现场照片" >
            <el-upload
                class="upload-demo"
                ref="uploadFileRef"
                :action="uploadFile"
                :headers='headers'
                :on-preview="handlePreview1"
                :on-remove="(file,lists)=>handleRemove1(file,lists,index)"
                :on-change="(file,filelists)=>handleChange1(file,filelists,index)"
                :on-success="handle_success1"
                :file-list="addQuestionForm.contentList[0].fileList"
                :auto-upload="true"
                list-type="picture"
                style="width: 60% /* 设定宽度 */"
                accept="docx,jpg,png,rar,pdf,word,"
            >
              <el-button slot="trigger" size="small" type="primary">选取上传文件</el-button>
<!--              <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload1(index)">先上传到服务器，再保存</el-button>-->
            </el-upload>
          </el-form-item>
          <div  style="margin: 5px auto;text-align: center;">
            <el-button @click="delQuestionCard(index)" >{{'删除'}}</el-button>
            <el-button type="primary" @click="addOpinionSave(index)" >{{'保存'}}</el-button>
          </div>

        </el-form>
      </el-card>
<!--      <div style="text-align: center; margin-top: 15px;">-->
<!--        <div style="width: 60%;height: 30px;border:1px dashed #DCDFE6;text-align: center;margin: 0 auto;" @click="addQuestionCard">-->
<!--          <i class="el-icon-plus"></i>-->
<!--          <span style="font-weight: bold">添加</span>-->
<!--        </div>-->
<!--      </div>-->


    </el-dialog>
            <!--  编辑问题  -->
    <el-dialog title="编辑问题"
               :visible.sync="dialog.addListDialog.editQuestion"
               v-model="dialog.addListDialog.editQuestion"
               :close-on-click-modal="false"
               :width="'50%'">
      <el-card  v-for="(list, index) in editQuestionForm.contentList" :key="index">
        <el-form :model="editQuestionForm.contentList[0]" label-position="top"  ref="" label-width="120px" class="">
          <el-form-item label="问题" >
<!--            <el-select style="width: 100%;"  v-model="editQuestionForm.contentList[0].question" placeholder="请选择问题" filterable clearable>-->
<!--              <el-option-->
<!--                  v-for="item in subCheckTypeList"-->
<!--                  :key="item.id"-->
<!--                  :label="item.name"-->
<!--                  :value="item.id"-->
<!--              >-->
<!--              </el-option>-->
<!--            </el-select>-->
            <el-input disabled v-model="editQuestionForm.contentList[0].question" :placeholder="editQuestionForm.contentList[index].question" ></el-input>
          </el-form-item>
          <el-form-item label="问题表述" >
<!--            <el-button type="primary" size="mini" @click="articlesDialog(index)" style="margin: 0 0 15px 0;" >{{'添加条文库问题'}}</el-button>-->
            <el-input type="textarea" v-model="editQuestionForm.contentList[0].problemStatement"  ></el-input>
          </el-form-item>
          <el-form-item label="违反规范条款" >
            <el-input v-model="editQuestionForm.contentList[0].violation" disabled></el-input>
          </el-form-item>
          <el-form-item label="现场照片" >
            <el-upload
                class="upload-demo"
                :action="uploadFile"
                :headers='headers'
                :on-preview="handlePreviewIsedit"
                :on-remove="(file,lists)=>handleRemoveIsedit(file,lists,index)"
                :on-change="(file,filelists)=>handleChangeEdit(file,filelists,index)"
                :on-success="handle_success_edit"
                :file-list="editQuestionForm.contentList[0].fileList"
                :auto-upload="true"
                list-type="picture"
                style="width: 60% /* 设定宽度 */"
                accept="docx,jpg,png,rar,pdf,word,"
            >
              <el-button slot="trigger" size="small" type="primary">选取上传文件</el-button>
              <!--              <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload1(index)">先上传到服务器，再保存</el-button>-->
            </el-upload>
          </el-form-item>
          <div  style="margin: 5px auto;text-align: center;">
            <el-button @click="delQuestionCard(index)" >{{'取消'}}</el-button>
            <el-button type="primary" @click="confirmAddListEdit(index)" >{{'保存'}}</el-button>
          </div>
        </el-form>
      </el-card>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible1" :width="'80%'">
      <!--      <template >-->
      <!--        <img width="100%" :src="dialogImageUrl" alt="Image"/>-->
      <!--      </template>-->
      <template >
        <!-- 这里显示 PDF 内容，你需要使用对应的方式来显示 PDF，例如使用 <embed> 或者 <iframe> 标签 -->
        <!--        <embed :src="dialogImageUrl" type="application/pdf" width="100%" />-->
        <!-- 或者使用 iframe 标签 -->
        <iframe :src="dialogImageUrl1" width="100%"  :style="{ height: '65vh' }"></iframe>
<!--        <image :src="dialogImageUrl1"></image>-->
      </template>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisibleEdit" :width="'80%'">
      <!--      <template >-->
      <!--        <img width="100%" :src="dialogImageUrl" alt="Image"/>-->
      <!--      </template>-->
      <template >
        <!-- 这里显示 PDF 内容，你需要使用对应的方式来显示 PDF，例如使用 <embed> 或者 <iframe> 标签 -->
        <!--        <embed :src="dialogImageUrl" type="application/pdf" width="100%" />-->
        <!-- 或者使用 iframe 标签 -->
<!--        <iframe :src="dialogImageUrl1" width="100%"  :style="{ height: '65vh' }"></iframe>-->
        <iframe style="min-height: 80vh;width: 100%" :src="`https://view.officeapps.live.com/op/view.aspx?src=${dialogImageUrl1}`" :key="url"  ></iframe>
<!--        <image :src="dialogImageUrl1"></image>-->
        <!--        <image :src="dialogImageUrl1"></image>-->
<!--        <el-button @click="downloadFile">下载</el-button>-->
        <a :href="dialogImageUrl1" download>
          <el-button>下载</el-button>
        </a>
      </template>
    </el-dialog>
                  <!--  添加条文库问题  -->
    <el-dialog title=""
               :visible.sync="dialog.addListDialog.articles"
               v-model="dialog.addListDialog.articles"
               :close-on-click-modal="false"
               :width="'50%'">
      <el-form :model="articlesForm"  ref="" label-width="120px" class="">
        <el-form-item label="搜索：">
          <template>
            <div @click="questionQuery">
              <el-input v-model="articlesForm.query" style="width: 90%;" readonly></el-input>
            </div>
          </template>
        </el-form-item>
        <el-form-item label="生成问题：" style="width: 90%">
          <div v-if="questionVal.questionOptions" v-for="(item,index) in questionParsingArray" :style="{ display: 'inline-block' }" :key="index">
            <div style="display: inline-block" v-if="item.includes('（')&&(typeRadio.length===1||index<1)">
              <el-select v-model="articlesForm.createQuestion"  @change="handleSelectChange" placeholder="请选择">
                <el-option
                    v-for="item in typeRadio[0]"
                    :key="item.value"
                    :label="item.value"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div style="display: inline-block" v-if="item.includes('（')&&(typeRadio.length>1&&index>1)">
              <el-select v-model="articlesForm.createQuestion1" @change="handleSelectChange" placeholder="请选择">
                <el-option
                    v-for="item in typeRadio[1]"
                    :key="item.value"
                    :label="item.value"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div style="display: inline-block" v-if="item.includes('{')&&(typeCheck.length===1||index<=1)">
              <el-select v-model="articlesForm.createQuestion2" multiple @change="handleSelectChange"  placeholder="请选择">
                <el-option
                    v-for="item in  typeCheck[0]"
                    :key="item.value"
                    :label="item.value"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div style="display: inline-block" v-if="item.includes('{')&&(typeCheck.length>1&&index>1)">
              <el-select v-model="articlesForm.createQuestion3" multiple @change="handleSelectChange"  placeholder="请选择">
                <el-option
                    v-for="item in  typeCheck[1]"
                    :key="item.value"
                    :label="item.value"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div style="display: inline-block;white-space: pre-line;" v-if="!item.includes('{')&&!item.includes('（')">
              {{item}}
            </div>
          </div>
          <div v-else>{{questionVal.questionDescription}}</div>
        </el-form-item>
        <el-form-item label="违反条文：">
          <el-input v-model="articlesForm.violationArticles" style="width: 90%;" disabled></el-input>
        </el-form-item>
        <el-form-item label="意见具体化：" >
          <el-input type="textarea" v-model="articlesForm.opinion" disabled style="width: 90%; height: auto"></el-input>
        </el-form-item>
        <el-form-item label="意见预览：">
          <el-input type="textarea" :rows="5" v-model="articlesForm.opinionPreview" disabled style="width: 90%; height: auto"></el-input>
        </el-form-item>
        <div style="margin: 5px auto;text-align: center;">
          <el-button @click.native="dialog.addListDialog.articles = false;">{{'取消'}}</el-button>
          <el-button type="primary" @click="addTiaoWen" >{{'确认添加'}}</el-button>
        </div>
      </el-form>
    </el-dialog>
                  <!--  问题库查询  -->
    <el-dialog title=""
               :visible.sync="dialog.addListDialog.questionQueryDialog"
               v-model="dialog.addListDialog.questionQueryDialog"
               :close-on-click-modal="false"
               :width="'50%'">
      <el-form :model="questionQueryForm"  label-width="120px" class="">
        <el-form-item label="问题搜索：">
          <el-input v-model="questionQueryForm.questionName" style="width: 90%;"
                    @blur="handleBlur"></el-input>
        </el-form-item>
        <el-form-item label="指定规范：">
          <el-select style="width: 90%" v-model="questionQueryForm.specification"
                     @change="specificationVal" placeholder="请选择" filterable>
            <el-option
                v-for="item in standardList[0]"
                :key="item.id"
                :label="item.label"
                :value="item.value"
                >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <div style="min-height: 150px;width: 90%;overflow-y: auto; border: 1px solid #DCDFE6" >
            <el-table :data="questionListQuery" @current-change='currentChangeQuestion'
                      highlight-current-row :show-header="false" style="width: 100%" height="80vh">
              <el-table-column  >
                <template #default="scope">
                  <el-tooltip  effect="light"  placement="left-start">
                    <div slot="content" style="max-width: 500px;">
                      <div v-if="scope.row.drStandardItemSerialContent">
                        {{scope.row.drStandardItemSerialNumber}}{{scope.row.drStandardItemSerialContent}}
                      </div>
                      <div v-if="scope.row.drStandardItemNotesId">
                        <div>{{JSON.parse(scope.row.drStandardItemNotesId).title}}</div>
                        <div><el-image
                            style="width: 100px; height: 100px"
                            :src="`${JSON.parse(scope.row.drStandardItemNotesId).picList}`"></el-image>
                        </div>
                        <div>{{JSON.parse(scope.row.drStandardItemNotesId).children.map((val)=>val.content)}}</div>
                      </div>
                    </div>
                  <div style="margin: 5px 0;">
                    （{{scope.row.ddIdQuestionPropertieValue}}）《{{scope.row.drStandardName}}》{{scope.row.drStandardItemSerialNumber}}
                    问题描述：{{scope.row.questionDescription}}
                  </div>
                  </el-tooltip>

                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form-item>
        <div style="margin: 5px auto;text-align: center;">
          <el-button @click.native="dialog.addListDialog.questionQueryDialog = false">{{'取消'}}</el-button>
          <el-button type="primary" @click="questionQueryConfirm" >{{'确认'}}</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!--  确认现场评定清单  -->
    <el-dialog title="确认现场评定清单"
               :visible.sync="dialog.confirmCheckList.show"
               v-model="dialog.confirmCheckList.show"
               :close-on-click-modal="false"
               :width="'60%'">
      <div style="width: 90%;margin-left: 40px;margin-top: 15px;">
<!--        <span style="font-size: large; ">现场评定清单:</span>-->
        <!--        <el-row v-if="!checkDialogisEdit" style="margin-top: 20px">-->
        <!--          <el-button type="primary" size="small">生成检查报告</el-button>-->
        <!--        </el-row>-->
        <el-form>
          <el-form-item style="width: 98% ;" v-for="(value,index) in opinionList " :key = 'index'>
            <el-card >
              <div>
                {{index+1}}、检查项目：{{value.mainCheckTypeValue}}/{{checkTypeList.find(item=>item.id===value.mainCheckType).subCheckTypeList.find(ite=>ite.id ===value.subCheckType).name}}
              </div>
              <div>
                {{value.checklistProblem}}
              </div>
              <div>
                违反规定条款：{{value.drStandardItemDescribe}}
              </div>
              <div>
                现场照片：
                <div v-if="value.fileList" class="demo-image__preview">
                  <el-image
                      style="width: 100px; height: 100px"
                      :src="value.fileList[0]?value.fileList[0].fileUrl:undefined"
                      :preview-src-list="value.fileList.map(item=>item.fileUrl)">
                    <div slot="error" class="image-slot">
                      暂无图片
                    </div>
                  </el-image>
                </div>

              </div>
                <span  style="display: flex; justify-content: flex-end;">
                  <i class="el-icon-edit-outline"   style="font-size: 22px;margin-right: 10px" @click="checkListEdit(value)"></i>
                  <i class="el-icon-delete"  style="font-size: large" @click="spliceCard(index)"></i>
                  <!--              <i class="el-icon-edit-outline"></i>-->
                </span>
              <div v-if="dialog.addListDialog.isEdit === value.id">
                <el-input :rows="4" type="textarea" v-model="checkProblem"></el-input>
                <div style="margin: 5px auto;text-align: center;">
                  <el-button @click.native="dialog.addListDialog.isEdit = false">{{'取消'}}</el-button>
                  <el-button type="primary" @click="checkEditProblem(value)" >{{'确认'}}</el-button>
                </div>
              </div>
            </el-card>
          </el-form-item>
          <el-divider></el-divider>
          <el-form-item style="width: 98% ;" v-for="(value,index) in spliceList " :key = 'index+1000'>
            <el-card >
              <div>
                {{index+1}}、检查项目：{{value.mainCheckTypeValue}}/{{checkTypeList.find(item=>item.id===value.mainCheckType).subCheckTypeList.find(ite=>ite.id ===value.subCheckType).name}}
              </div>
              <div>
                {{value.confirmProblem}}
              </div>
              <div>
                违反规定条款：{{value.drStandardItemDescribe}}
              </div>
              <div>
                现场照片：
                <div v-if="value.fileList" class="demo-image__preview">
                  <el-image
                      style="width: 100px; height: 100px"
                      :src="value.fileList[0]?value.fileList[0].fileUrl:undefined"
                      :preview-src-list="value.fileList.map(item=>item.fileUrl)">
                    <div slot="error" class="image-slot">
                      暂无图片
                    </div>
                  </el-image>
                </div>

              </div>
              <span  style="display: flex; justify-content: flex-end;">
<!--                <i class="el-icon-delete" v-if="!checkDialogisEdit" style="font-size: large" @click="spliceCardAll(index+1000)"></i>-->
                <i class="el-icon-delete"  style="font-size: large" @click="spliceCardAll(index+1000)"></i>
                <!--              <i class="el-icon-edit-outline"></i>-->
              </span>
            </el-card>
          </el-form-item>
        </el-form>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary"  @click="confirmCheckListPost">确认生成清单</el-button>
      </div>
    </el-dialog>
    <!--  添加验收评定报告  -->
    <el-dialog  title=""
               :visible.sync="dialog.addReportDialog.show"
               v-model="dialog.addReportDialog.show"
               :close-on-click-modal="false"
               :width="'50%'">
<!--      <div style="height: 100%">-->
<!--        <iframe style="min-height: 80vh;width: 100%" :src="`https://view.officeapps.live.com/op/view.aspx?src=${docxUrl}`" :key="url"  lazy></iframe>-->
<!--      </div>-->
      <el-form v-if="reportForm" :model="reportForm" :rules="reportFormRules" ref="reportFormRef" label-width="180px">
        <el-form-item prop="projectName" label="项目名称：">
          <el-input :disabled="reportJ" v-model="reportForm.projectName" style="width: 80%"></el-input>
        </el-form-item>
<!--        <el-form-item prop="" label="住建局：">-->
<!--          <el-input v-model="reportForm."></el-input>-->
<!--        </el-form-item>-->
        <el-form-item prop="projectCompany" label="申请公司：">
          <el-input :disabled="reportA" v-model="reportForm.projectCompany" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item prop="projectAddress" label="项目地址：">
          <el-input :disabled="reportB" v-model="reportForm.projectAddress" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item prop="buildStorey" label="建筑层数：">
          <el-input :disabled="reportC" v-model="reportForm.buildStorey" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item prop="buildHeight" label="建筑高度：">
          <el-input :disabled="reportD" v-model="reportForm.buildHeight" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item prop="buildArea" label="建筑面积：">
          <el-input :disabled="reportE" v-model="reportForm.buildArea" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item prop="natureOfUse" label="使用性质：">
          <el-input :disabled="reportF" v-model="reportForm.natureOfUse" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item prop="applyProjectLocation" label="申请项目位置：">
          <el-input :disabled="reportG" v-model="reportForm.applyProjectLocation" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item prop="applyBuildArea" label="申请建筑面积：">
          <el-input :disabled="reportH" v-model="reportForm.applyBuildArea" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item prop="applyNatureOfUse" label="申请使用性质：">
          <el-input :disabled="reportI" v-model="reportForm.applyNatureOfUse" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item prop="projectNotes" label="消防设施概况：">
          <el-input  type="textarea" :rows="4" v-model="reportForm.projectNotes" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="评定结论：">
          <el-radio-group v-model="reportForm.optionValue">
            <el-radio disabled :label="1">不合格</el-radio>
            <el-radio disabled :label="2">合格</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

<!--      <div style="margin: 5px auto;text-align: center;">-->
<!--        <el-button @click.native="dialog.addReportDialog.show = false">{{'取消'}}</el-button>-->
<!--        <el-button type="primary" @click="confirmAddCheckList" >{{'确认'}}</el-button>-->
<!--      </div>-->
      <div style="width: 90%;margin-left: 40px;margin-top: 15px;">
        <span style="font-size: large; ">现场评定清单:</span>
        <el-row style="margin: 20px 0">
          <el-button type="primary"  @click="confirmAddCheckList" >生成验收报告</el-button>
        </el-row>
        <el-form>
          <el-form-item style="width: 98% ;" v-for="(value,index) in opinionListReport " :key = 'index'>
            <el-card >
              <div>
                {{index+1}}、检查项目：{{value.mainCheckTypeValue}}/{{value.subCheckTypeValue}}
<!--                /{{checkTypeListReport.find(item=>item.id===value.mainCheckType).subCheckTypeList.find(ite=>ite.id ===value.subCheckType).name}}-->
              </div>
              <div>
                {{value.reportProblem?value.reportProblem:value.confirmProblem}}
              </div>
              <div v-if="dialog.addReportDialog.isEdit">
                <el-input :rows="4" type="textarea" v-model="reportEditValue"></el-input>
                <div style="margin: 5px auto;text-align: center;">
                  <el-button @click.native="dialog.addReportDialog.isEdit = false">{{'取消'}}</el-button>
                  <el-button type="primary" @click="confirmReportEdit(value)" >{{'确认'}}</el-button>
                </div>
              </div>
              <div>
                违反规定条款：{{value.drStandardItemDescribe}}
              </div>
              <div>
                现场照片：
                <div v-if="value.fileList" class="demo-image__preview">
                  <el-image
                      style="width: 100px; height: 100px"
                      :src="value.fileList[0]?value.fileList[0].fileUrl:undefined"
                      :preview-src-list="value.fileList.map(item=>item.fileUrl)">
                    <div slot="error" class="image-slot">
                      暂无图片
                    </div>
                  </el-image>
                </div>

              </div>
              <span  style="display: flex; justify-content: flex-end;">
                <i class="el-icon-edit-outline" style="font-size:22px;margin-right: 10px" @click="reportCardEdit(value)"></i>
                <i class="el-icon-delete" style="font-size: 22px" @click="spliceCard1(index)"></i>

              </span>
            </el-card>
          </el-form-item>
          <el-divider></el-divider>
          <el-form-item style="width: 98% ;" v-for="(value,index) in spliceList1 " :key = 'index+1000'>
            <el-card >
              <div>
                {{index+1}}、检查项目：{{value.mainCheckTypeValue}}/{{value.subCheckTypeValue}}
<!--                /{{checkTypeListReport.find(item=>item.id===value.mainCheckType).subCheckTypeList.find(ite=>ite.id ===value.subCheckType).name}}-->
              </div>
              <div>
                {{value.reportProblem?value.reportProblem:value.confirmProblem}}
              </div>
              <div>
                违反规定条款：{{value.drStandardItemDescribe}}
              </div>
              <div>
                现场照片：
                <div v-if="value.fileList" class="demo-image__preview">
                  <el-image
                      style="width: 100px; height: 100px"
                      :src="value.fileList[0]?value.fileList[0].fileUrl:undefined"
                      :preview-src-list="value.fileList.map(item=>item.fileUrl)">
                    <div slot="error" class="image-slot">
                      暂无图片
                    </div>
                  </el-image>
                </div>

              </div>
              <span  style="display: flex; justify-content: flex-end;">
                <i class="el-icon-refresh-left" style="font-size: large" @click="spliceCardAll1(index+1000)"></i>
                <!--              <i class="el-icon-edit-outline"></i>-->
              </span>
            </el-card>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog title="提交验收评定报告"
               :visible.sync="dialog.addReportDialog.addOpinion"
               v-model="dialog.addReportDialog.addOpinion"
               :close-on-click-modal="false"
               :width="'50%'">
      <el-form :model="addReportOpinion" label-width="120px">
        <el-form-item label="报告编号：">
          <el-input v-model="addReportOpinion.customReportNo" style="width: 90%"></el-input>
        </el-form-item>
        <el-form-item label="项目备注：">
          <el-input v-model="addReportOpinion.projectNotes" type="textarea" style="width: 90%"> </el-input>
        </el-form-item>
      </el-form>
      <div style="margin: 5px auto;text-align: center;">
        <el-button @click="addReportClosed" >{{'取消'}}</el-button>
        <el-button type="primary" @click="addReportDialog" >{{'确认'}}</el-button>
      </div>
    </el-dialog>
    <!--  添加复验  -->
    <el-dialog title="添加复验"
               :visible.sync="dialog.addRetestDialog.show"
               v-model="dialog.addRetestDialog.show"
               :close-on-click-modal="false"
               :width="'50%'">
      <el-form label-width="188px" v-model="recheckForm">
        <el-form-item  label="验收时间：">
<!--          {{showNowTime()}}-->
          <template #default="scope">
            <div class="block">
              <el-date-picker
                  v-model="recheckForm.addTime"
                  type="date"
                  placeholder="选择日期"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd">
              </el-date-picker>
            </div>
          </template>
<!--          <el-input v-model="recheckForm.addTime" style="width: 80%"></el-input>-->
        </el-form-item>
        <el-form-item label="添加整改报告：">
          <el-upload
              class="upload-demo"
              ref="uploadFileRecheck"
              :action="uploadFile"
              :headers='headers'
              :on-preview="handlePreviewRecheck"
              :on-remove="(file,lists)=>handleRemoveRecheck(file,lists)"
              :on-change="(file,filelists)=>handleChangeRecheck(file,filelists)"
              :on-success="handleSuccessRecheck"
              :file-list="ImageUrlRecheck"
              :auto-upload="true"
              list-type="picture"
              style="width: 60% /* 设定宽度 */"
          >
            <el-button slot="trigger" size="small" type="primary">选取上传文件</el-button>
            <!--              <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload1(index)">先上传到服务器，再保存</el-button>-->
          </el-upload>
        </el-form-item>
      </el-form>
      <div style="margin: 5px auto;text-align: center;">
        <el-button @click="addRecheckClosed" >{{'取消'}}</el-button>
        <el-button type="primary" @click="addRecheckConfirm" >{{'确认'}}</el-button>
      </div>
    </el-dialog>
          <!--  添加复验时的图片列表  -->
    <el-dialog :visible.sync="dialog.addRetestDialog.photo" :width="'80%'">
      <template >
        <iframe :src="ImageUrlRecheck" width="100%"  :style="{ height: '65vh' }"></iframe>
      </template>
    </el-dialog>

    <!--  项目详情查看  -->
    <el-dialog title="项目详情" :visible.sync="dialog.projectDetailDialog.show" :width="'50%'">
      <el-form :model="projectDetailForm" label-width="150px" class="demo-ruleForm">
        <el-form-item label="省：" prop="provinceCode">
          <el-input v-model="projectDetailForm.provinceCode" readonly style=" width:60%;"></el-input>
        </el-form-item>
        <el-form-item label="市：" prop="provinceCode">
          <el-input v-model="projectDetailForm.cityCode" readonly style=" width:60%;"></el-input>
        </el-form-item>
        <el-form-item label="区：" prop="provinceCode">
          <el-input v-model="projectDetailForm.areaCode" readonly style=" width:60%;"></el-input>
        </el-form-item>
        <el-form-item label="工程名称：" prop="projectName">
          <el-input v-model="projectDetailForm.projectName"  readonly style=" width:60%;"></el-input>
        </el-form-item>
        <el-form-item label="工程类别：" prop="ddIdProjectType">
          <el-radio-group v-model="projectDetailForm.ddIdProjectType">
            <el-radio disabled :label="31">新建</el-radio>
            <el-radio disabled :label="33">改造</el-radio>
            <el-radio disabled :label="279">装修</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="工程地址：" prop="address">
          <el-input style="width: 60%" v-model="projectDetailForm.address" readonly></el-input>
        </el-form-item>
        <el-form-item label="项目申请单位：" prop="projectCompany">
          <el-input style="width: 60%" v-model="projectDetailForm.projectCompany" readonly></el-input>
        </el-form-item>
        <el-form-item label="验收时间：" prop="firstDate">
          <el-input style="width: 60%" v-model="projectDetailForm.firstDate" readonly></el-input>
        </el-form-item>
        <el-form-item label="通知：">
          <el-radio v-model="projectDetailForm.notify" :label="1">企业微信通知</el-radio>
        </el-form-item>
        <el-form-item label="文件列表：">
          <div v-if="projectDetailForm.fileList" class="demo-image__preview" >
            <el-image
                style="width: 100px; height: 100px"
                :src="projectDetailForm.fileList[0]?projectDetailForm.fileList[0].fileUrl:undefined"
                :preview-src-list="projectDetailForm.fileList.map(item=>item.fileUrl)">
              <div slot="error" class="image-slot">
                暂无图片
              </div>
            </el-image>

<!--            <el-upload-->
<!--                :action="uploadFile"-->
<!--                :headers='headers'-->
<!--                :on-preview="handlePreviewRecheck"-->
<!--                :file-list="projectDetailForm.fileList.map(item=>({name:item.projectName,url:item.fileUrl}))"-->
<!--                :auto-upload="false"-->
<!--                list-type="picture"-->
<!--                style="width: 60% /* 设定宽度 */"-->
<!--                :preview-src-list="projectDetailForm.fileList.map(item=>item.fileUrl)">-->

<!--            </el-upload>-->
<!--            <iframe :src="ImageUrlRecheck" width="100%"  :style="{ height: '65vh' }"></iframe>-->
          </div>

        </el-form-item>
      </el-form>
    </el-dialog>
    <!--  现场评定清单查看  -->
     <el-dialog title="现场评定清单"
               :visible.sync="dialog.listDialog.show"
               v-model="dialog.listDialog.show"
               :close-on-click-modal="false"
               :width="'60%'">
      <el-table
          :data="opinionWorkFlowIdArray"
          border
          style="width: 100%"
          v-loading="listLoading"
          ref="multipleTable"
          :highlight-current-row='true'
      >
        <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
        <el-table-column prop="projectNo" label="评定清单" width='' align="center">
          <template #default="scope">
            <div style="text-align: center;">
              评定清单{{scope.row.acceptanceNo}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="opinionCreateTime" label="添加时间" width='' align="center"></el-table-column>
        <el-table-column prop="projectNo" label="操作" width='' align="center">
          <template #default="scope">
            <div style="text-align: center;">
              <el-button  type="text" @click="stageChecklistCheck(scope.row)">
                {{'查看'}}
              </el-button>
              <el-button v-if="scope.row.opinionIsEdit===true" type="text" @click="stageChecklistEdit(scope.row)">
                {{'编辑'}}
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!--  现场评定抽选检查查看  -->
    <el-dialog title="现场评定抽选检查"
               :visible.sync="dialog.checkDialog.show"
               v-model="dialog.checkDialog.show"
               :close-on-click-modal="false"
               :width="'60%'">
      <el-table
          :data="checklistOpinionCreateTimeArray"
          border
          style="width: 100%"
          v-loading="listLoading"
          ref="multipleTable"
          :highlight-current-row='true'
      >
        <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
        <el-table-column prop="projectNo" label="现场评定抽选检查" width='' align="center">
          <template #default="scope">
            <div style="text-align: center;">
              现场抽选评定检查表{{scope.row.acceptanceNo}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="checklistOpinionCreateTime" label="添加时间" width='' align="center"></el-table-column>
        <el-table-column prop="projectNo" label="操作" width='' align="center">
          <template #default="scope">
            <div style="text-align: center;">
              <span style="margin-right: 10px">
<!--                <el-button type="text" v-if="scope.row.checklistOpinionIsEdit === false" @click="checkingDialog(scope.row)">-->
<!--                {{'查看'}}-->
<!--              </el-button>-->
                 <el-button type="text"  @click="checkingDialog(scope.row)">
                {{'查看'}}
              </el-button>
<!--              <el-button type="text" v-else-if="scope.row.checklistOpinionIsEdit === true" @click="checkingDialogEdit(scope.row)">-->
<!--                {{'编辑'}}-->
<!--              </el-button>-->
<!--                <el-button v-else>{{'无评定检查表'}}</el-button>-->
              </span>
              <a :href=scope.row.checklistFileUrl>{{'下载'}}</a>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
        <!--  现场评定抽选检查查看查看  -->
    <el-dialog title="现场评定抽选检查"
               :visible.sync="dialog.checkDialog.checking"
               v-model="dialog.checkDialog.checking"
               :close-on-click-modal="false"
               :width="'50%'">
      <table style="width: 90%;margin-left: 40px;margin-bottom: 40px">
        <thead>
          <tr>
            <th colspan="5"  style="text-align: center;">现场评定抽选检查</th>
          </tr>
          <tr>
            <th style="text-align: center;" >工程名称</th>
            <th colspan="4"><p>{{ needData.projectName }}</p></th>
          </tr>
          <tr>
            <th style="text-align: center;">抽选部分</th>
            <th colspan="2" style="text-align: center;">检查项目</th>
            <th colspan="2" style="text-align: center;">评定结论</th>
          </tr>
        </thead>
        <tbody>
        <tr v-for="(rowData, index) in checkTypeList" :key="rowData.id">
          <td></td>
          <td colspan="2"  style="text-align: center;">{{ rowData.name }}</td>
          <td colspan="2"  style="text-align: center;">
            <el-radio-group v-model="rowData.optionValue">
              <el-radio disabled :label="1">不合格</el-radio>
              <el-radio disabled :label="2">合格</el-radio>
            </el-radio-group>
          </td>
        </tr>
        </tbody>
        <thead>
        <tr>
          <th colspan="1"  style="text-align: center;height: 80px">随机抽选过程见证</th>
          <th colspan="4"  style="text-align: left; height: 80px; position: relative;">
            <div style="font-size: large">以上部位做到了随机抽选，无异议</div>
            <div style="margin-top: 10px">与会代表鉴定确认：</div>
            <div style="position: absolute; bottom: 0; right: 0;margin-bottom: 5px">
              年&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              月&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              日&nbsp;&nbsp;</div>
          </th>
        </tr>
        <tr>
          <th colspan="1"  style="text-align: center;height: 80px">综合评定结论</th>
          <th colspan="4"  style="text-align: left;height: 80px;position: relative;">
            <div>
              <el-radio-group v-model="radioChange">

                <el-radio disabled :label="1">合格</el-radio>
                <el-radio disabled :label="2">不合格</el-radio>
              </el-radio-group>
              <span style="font-size: large"> ,后附问题清单</span>
            </div>
            <div style="margin-top: 10px">
              现场评定人员签字：
            </div>
            <div style="position: absolute; bottom: 0; right: 0;margin-bottom: 5px">
              年&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              月&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              日&nbsp;&nbsp;</div>
          </th>
        </tr>
        </thead>
      </table>
      <hr></hr>
      <div style="width: 90%;margin-left: 40px;margin-top: 15px;">
        <span style="font-size: large; ">现场评定清单:</span>
<!--        <el-row v-if="!checkDialogisEdit" style="margin-top: 20px">-->
<!--          <el-button type="primary" size="small">生成检查报告</el-button>-->
<!--        </el-row>-->
        <el-form>
          <el-form-item style="width: 98% ;" v-for="(value,index) in opinionList " :key = 'index'>
            <el-card >
              <div>
                {{index+1}}、检查项目：{{value.mainCheckTypeValue}}/{{checkTypeList.find(item=>item.id===value.mainCheckType).subCheckTypeList.find(ite=>ite.id ===value.subCheckType).name}}
              </div>
              <div>
                {{value.confirmProblem}}
              </div>
              <div>
                违反规定条款：{{value.drStandardItemDescribe}}
              </div>
              <div>
                现场照片：
                <div v-if="value.fileList" class="demo-image__preview">
                  <el-image
                      style="width: 100px; height: 100px"
                      :src="value.fileList[0]?value.fileList[0].fileUrl:undefined"
                      :preview-src-list="value.fileList.map(item=>item.fileUrl)">
                    <div slot="error" class="image-slot">
                      暂无图片
                    </div>
                  </el-image>
                </div>

              </div>
<!--              <span  style="display: flex; justify-content: flex-end;">-->
<!--                <i class="el-icon-delete" v-if="!checkDialogisEdit" style="font-size: large" @click="spliceCard(index)"></i>-->
<!--                &lt;!&ndash;              <i class="el-icon-edit-outline"></i>&ndash;&gt;-->
<!--              </span>-->
            </el-card>
          </el-form-item>
          <el-divider></el-divider>
          <el-form-item style="width: 98% ;" v-for="(value,index) in spliceList " :key = 'index+1000'>
            <el-card >
              <div>
                {{index+1}}、检查项目：{{value.mainCheckTypeValue}}/{{checkTypeList.find(item=>item.id===value.mainCheckType).subCheckTypeList.find(ite=>ite.id ===value.subCheckType).name}}
              </div>
              <div>
                {{value.confirmProblem}}
              </div>
              <div>
                违反规定条款：{{value.drStandardItemDescribe}}
              </div>
              <div>
                现场照片：
                <div v-if="value.fileList" class="demo-image__preview">
                  <el-image
                      style="width: 100px; height: 100px"
                      :src="value.fileList[0]?value.fileList[0].fileUrl:undefined"
                      :preview-src-list="value.fileList.map(item=>item.fileUrl)">
                    <div slot="error" class="image-slot">
                      暂无图片
                    </div>
                  </el-image>
                </div>

              </div>
              <span  style="display: flex; justify-content: flex-end;">
                <i class="el-icon-delete" v-if="!checkDialogisEdit" style="font-size: large" @click="spliceCardAll(index+1000)"></i>
                <!--              <i class="el-icon-edit-outline"></i>-->
              </span>
            </el-card>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!--  整改报告查看  -->
    <el-dialog title="整改报告"
               :visible.sync="dialog.reportDialog.show"
               v-model="dialog.reportDialog.show"
               :close-on-click-modal="false"
               :width="'60%'">
      <el-table
          :data="rectify"
          border
          style="width: 100%"
          v-loading="listLoading"
          ref="multipleTable"
          :highlight-current-row='true'
      >
        <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
        <el-table-column prop="stageName" label="整改报告" width='' align="center">
          <template #default="scope">
            整改报告{{scope.row.acceptanceNo-1}}
          </template>
        </el-table-column>
        <el-table-column prop="projectNo" label="报告" width='' align="center">
          <template #default="scope">
            <div v-if="scope.row.fileList" class="demo-image__preview">
              <el-image
                  style="width: 100px; height: 100px"
                  :src="scope.row.fileList[0]?scope.row.fileList[0].fileUrl:undefined"
                  :preview-src-list="scope.row.fileList.map(item=>item.fileUrl)">
                <div slot="error" class="image-slot">
                  暂无图片
                </div>
              </el-image>
            </div>
          </template>
        </el-table-column>
<!--        <el-table-column prop="stageCreateTime" label="创建时间" width='' align="center"></el-table-column>-->
        <el-table-column prop="acceptanceDate" label="验收时间" width='' align="center"></el-table-column>
      </el-table>
    </el-dialog>

    <!--  验收评定报告查看  -->
    <el-dialog title="验收评定报告"
               :visible.sync="dialog.accReportDialog.show"
               v-model="dialog.accReportDialog.show"
               :close-on-click-modal="false"
               :width="'60%'">
      <el-table
          :data="reportOpinionWorkFlowIdArray"
          border
          style="width: 100%"
          v-loading="listLoading"
          ref="multipleTable"
          :highlight-current-row='true'
      >
        <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
        <el-table-column prop="stageName" label="验收意见" width='' align="center"></el-table-column>
        <el-table-column prop="reportOpinionCreateTime" label="添加时间" width='' align="center">
        </el-table-column>
        <el-table-column prop="projectNo" label="操作" width='' align="center">
          <template #default="scope">
            <div style="text-align: center;">
              <span style="margin-right: 10px;">
                <el-button type="text" @click="evaluationReportCheck(scope.row)">
                {{'查看'}}
              </el-button>
              </span>
              <a :href="scope.row.reportFileUrl">下载</a>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog title="验收评定报告"
               :visible.sync="dialog.accReportDialog.check"
               v-model="dialog.accReportDialog.check"
               :close-on-click-modal="false"
               :width="'60%'">
      <iframe style="min-height: 80vh;width: 100%" :src="`https://view.officeapps.live.com/op/view.aspx?src=${evaluationReportUrl}`" :key="url"  ></iframe>
    </el-dialog>
    <!--  项目阶段查看  -->
    <el-dialog title="项目阶段"
               :visible.sync="dialog.stageProjectDialog.show"
               v-model="dialog.stageProjectDialog.show"
               :close-on-click-modal="false"
               :width="'60%'">
      <el-table
          :data="stageDetail"
          border
          style="width: 100%"
          v-loading="listLoading"
          ref="multipleTable"
          :highlight-current-row='true'
      >
        <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
        <el-table-column prop="projectNo" label="验收阶段" width='' align="center"></el-table-column>
        <el-table-column prop="projectNo" label="现场评定清单" width='' align="center">
          <template #default="scope">
            <div style="text-align: center;">
              <el-button type="text" @click="stageChecklistCheck(scope.row)">
                {{'查看'}}
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="projectNo" label="验收意见" width='' align="center">
          <template #default="scope">
            <div style="text-align: center;">
              <el-button type="text" @click="checkingDialog(scope.row)">
                {{'查看'}}
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="projectNo" label="整改报告" width='' align="center">
          <template #default="scope">
            <div style="text-align: center;">
              <el-button type="text" @click="reportChecking">
                {{'查看'}}
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="projectNo" label="不合格检查项目" width='' align="center">
          <template #default="scope">
            <div style="text-align: center;">
              <el-button type="text" @click="stageProject">
                {{'查看'}}
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="projectNo" label="验收时间" width='' align="center"></el-table-column>
      </el-table>
    </el-dialog>

  </div>
</template>

<script>
import util from '../../../util/date'
import Toolbar from '../../components/Toolbar'
// @ts-ignore
import ObsClient from 'esdk-obs-browserjs/src/obs'
import { getButtonList } from '../../promissionRouter'
import {
  getAreaDictionaryList,
	appendLocalExpertUsers,
  assignLocalExpertUsers,
  getAcceptanceProjectList,
  getByNamespace,
  getCurrentChecklistOpinionList,
  addProjectAcceptance,
  getAllProjectList,
	getLocalExpertList,
  uploadFile,
  getCurrentDrContractList,
  addQuestion,
  getAcceptanceQuestionPageList,
  getDrStandardList,
  getCurrentOpinionList,
  addOpinion,
  deleteOpinionAcceptance,
  getAcceptanceByProjectId,
  getChecklistOpinionListByWorkFlowId,
  getOpinionListByWorkFlowIdAcceptance,
  generateCheckList,
  submitConfirmOpinion,
  getReportInfoByWorkFlowId,
  getReportInfoByWorkFlowIdAcceptance,
  getCurrentReportInfo,
  updateReportOptions,
  updateOptions, generateReport, addReviewTask, updateChecklistOptions, updateEngineerProject,
  getProjectApplicationByProjectId
} from '@/api/api'
import Vue from 'vue'
import FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import addTiaoWen from "@/components/AddTiaoWen.vue";

export default {
  components: { Toolbar },
  data () {
    return {
      rectify:undefined,
      radioChange:1,
      recheckForm:{
        addTime:undefined,
        addReportList:[],
      },
      listEditValue:'',
      reportEditValue:'',
      reportBoolean:false,
      reportForm:{
        projectName:undefined,//项目名
        projectCompany:undefined,//申请公司
        projectAddress:undefined,//项目地址
        buildStorey:undefined,//建筑层数
        buildHeight:undefined,//建筑高度
        buildArea:undefined,//建筑面积
        natureOfUse:undefined,//使用性质
        applyProjectLocation:undefined,//申请项目位置
        applyBuildArea:undefined,//申请建筑面积
        applyNatureOfUse:undefined,//申请使用性质
        projectNotes:undefined,//
        optionValue:2,
      },
      reportFormRules:{
        projectName:[
          {required: true, message: '请输入项目名', trigger: 'blur' }
        ],//项目名
        projectCompany:[
          {required: true, message: '请输入申请公司', trigger: 'blur' }
        ],//申请公司
        projectAddress:[
          {required: true, message: '请输入项目地址', trigger: 'blur' }
        ],//项目地址
        buildStorey:[
          {required: true, message: '请输入建筑层数', trigger: 'blur' }
        ],//建筑层数
        buildHeight:[
          {required: true, message: '请输入建筑高度', trigger: 'blur' }
        ],//建筑高度
        buildArea:[
          {required: true, message: '请输入建筑面积', trigger: 'blur' }
        ],//建筑面积
        natureOfUse:[
          {required: true, message: '请输入使用性质', trigger: 'blur' }
        ],//使用性质
        applyProjectLocation:[
          {required: true, message: '请输入申请项目位置', trigger: 'blur' }
        ],//申请项目位置
        applyBuildArea:[
          {required: true, message: '请输入申请建筑面积', trigger: 'blur' }
        ],//申请建筑面积
        applyNatureOfUse:[
          {required: true, message: '请输入申请使用性质', trigger: 'blur' }
        ],//申请使用性质
        projectNotes:[
          {required: true, message: '请输入申请使用性质', trigger: 'blur' }
        ],//
      },
      addListOpinion:{
        customReportNo:undefined,//报告编号自定义
        projectId:undefined,//
        projectInfo:undefined,//
        projectNotes:undefined,//项目备注
      },
      addReportOpinion:{
        customReportNo:undefined,//报告编号自定义
        projectId:undefined,//
        projectInfo:undefined,//
        projectNotes:undefined,//项目备注
      },
      dialogVisibleTooltip:false,
      questionQueryForm:{
        questionName:undefined,
        specification:undefined,
      },
      addQuestionForm:{
        contentList:[
          {
            question:'',
            problemStatement:'',//问题表述
            problemStatement1:'',//问题表述
            violation:'',//违反规范条款
            fileList:[],//照片列表
          }
        ]
      },
      editQuestionForm:{
        contentList:[
          {
            question:'',
            problemStatement:'',//问题表述
            problemStatement1:'',//问题表述
            violation:'',//违反规范条款
            fileList:[],//照片列表
          }
        ]
      },
      articlesForm:{
        query:'',//搜索
        createQuestion:'',//生成问题
        createQuestion1:'',//生成问题
        createQuestion2:'',//生成问题
        createQuestion3:'',//生成问题
        violationArticles:'',//违反条文
        opinion:'',//意见具体化
        opinionPreview:'',//意见预览
      },
      url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      dialogImageUrl: '',
      dialogImageUrl1: '',
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisibleEdit: false,
      disabled: false,
      fileList:[],
      fileList1:[],
      projectDetailForm:{
        projectName:'',//工程名称
        ddIdProjectType:31,//工程类别
        provinceCode:undefined,//省
        cityCode:undefined,//市
        areaCode:undefined,//区
        address:'',//工程地址
        notify:1,//通知
        projectCompany:undefined,//项目申请单位
        firstDate:undefined,//初次验收日期
        fileList:[],//图片列表
      },
      addAcceptanceForm:{
        projectName:'',//工程名称
        ddIdProjectType:31,//工程类别
        provinceCode:undefined,//省
        cityCode:undefined,//市
        areaCode:undefined,//区
        address:'',//工程地址
        notify:1,//通知
        projectCompany:undefined,//项目申请单位
        firstDate:undefined,//初次验收日期
      },
      editAcceptanceForm:{
        projectName:'',//工程名称
        ddIdProjectType:31,//工程类别
        provinceCode:undefined,//省
        cityCode:undefined,//市
        areaCode:undefined,//区
        address:'',//工程地址
        notify:1,//通知
        projectCompany:undefined,//项目申请单位
        firstDate:undefined,//初次验收日期
        fileList:undefined,//资料列表
      },
      addAcceptanceFormRules:{
        firstDate:[
          {required: true, message: '请输入初次验收日期', trigger: 'blur' }
        ],
        projectCompany:[
          {required: true, message: '请输入项目申请单位', trigger: 'change' }
        ],
        projectName:[
          { required: true, message: '请输入工程项目名称', trigger: 'blur' },
          // { min: 3, max: 55, message: '长度在 3 到 55 个字符', trigger: 'blur' }
        ],//工程名称
        ddIdProjectType:[
          { required: true, message: '请选择工程类别', trigger: 'change' }
        ],//工程类别
        provinceCode:[
          { required: true, message: '请选择住建局', trigger: 'blur' }
        ],//省
        cityCode:undefined,//市
        areaCode:undefined,//区
        address:[
          { required: true, message: '请输入工程地址', trigger: 'blur' }
        ],//工程地址
        notify:undefined,//通知
      },
      addAcceptanceTask:{//验收任务分工
        person:[],
      },
      ruleForm: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入工程项目名称', trigger: 'blur' },
          { min: 3, max: 55, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        date1: [
          { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
        ],
        date2: [
          { type: 'date', required: true, message: '请选择时间', trigger: 'change' }
        ],
      },
      radio:1,
      taskRadio:1,
      expertOptions:[
        {value:'未开始',id:254},
        {value:'验收中',id:255},
        // {value:'完成',id:256},
      ],
      dataCount:undefined,
      userOpinionDetailList:[],
      utils: '',
      buttonList: [],
      needData: '',
      filters: {
        areaCode:undefined,//区
        cityCode:undefined,//市
        firstDate:'',//验收开始时间
        name:'',//工程名称
        expertType:undefined,//状态
        provinceCode:undefined,//省份
      },
      tableData: [],
      projectTableData: [],
      refuseTableData: [],
      refuseTableData1: [],
      datatoExport: [],
      overdueDataTableData: [],
      listLoading: false,
      dialog: {
        createDialog: {        //创建工程项目
          show: false,
          edit:false,
        },
        acceptanceDialog: {    //验收任务分工
          show: false
        },
        addListDialog: {       //添加现场评定清单
          show: false,
          nextStep:false ,      //下一步
          addQuestion:false,    //添加问题
          articles:false,       //添加条文
          questionQueryDialog:false,//问题库查询
          addOpinion:false,     //确认生成清单
          isEdit:undefined,         //编辑
          editQuestion:false,   //编辑问题
        },
        confirmCheckList:{      //确认现场评定清单
          show:false,
        },
        addReportDialog: {     //添加验收评定报告
          show: false,
          isEdit:false,        //编辑card
          addOpinion:false,
        },
        addRetestDialog: {     //添加复验
          show: false,          //弹窗显示
          photo:false           //照片详情
        },
        projectDetailDialog:{   //项目详情查看
          show:false,
        },
        listDialog:{           //现场评定清单查看
          show:false,
        },
        checkDialog:{          //现场评定抽选检查
          show:false,
          checking:false,      //查看
        },
        reportDialog:{         //整改报告
          show:false,
        },
        accReportDialog:{      //验收评定报告
          show:false,
          check:false,          //查看
        },
        stageProjectDialog:{   //项目阶段
          show:false,
        }
      },
      pickerData: [],
      provinceSearchOptions: [],//省
      isExecuting: false,
      refuseId: '',
      projectId: '',
      //关于分页的obj
      pages: {
        pageSize: 20,//默认每页条数
        pageArr: [10, 20, 30, 40],//默认每页条数选择
        pageIndex: 1, //默认进入页
        dataCount: this.dataCount, //默认总页数
      },
      companyIdList:[],
      changeAreaObj:{},
      changeProjectList:[],
      projectObj:undefined,
      currentYear: 0,
      currentMonth:0,
      acceptanceTaskList:[],//验收任务分工人员列表
      obsClient: null,
      uploadFile:'',
      uploadList:[],
      uploadList1:[],
      uploadListEdit:[],
      checkTypeList:undefined,
      checkProjectName:[],//检查项目（不合格）
      subCheckTypeList:[],//问题子类列表
      questionListQuery:[],//条文问题列表
      standardList:[],//
      tooltipVisible: false,
      tooltipContent: '',
      current:0,
      setContent:undefined,
      questionVal:{},
      resultArray:[],
      standardId:undefined,
      selectedOption: "",
      questionParsingArray:[],
      typeCheck:[],
      typeRadio:[],
      articlesIndex:0,
      questionPush:'',
      questionArray:[],
      questionValId:{},
      opinionList:[],
      stageDetail:[],
      checkDialog:true,
      checkDialogisEdit:false,
      spliceList:[],
      spliceList1:[],
      confirmOpinionCheckList:[],
      docxUrl:'',
      reportA:false,
      reportB:false,
      reportC:false,
      reportD:false,
      reportE:false,
      reportF:false,
      reportG:false,
      reportH:false,
      reportI:false,
      reportJ:false,
      opinionListReport:[],
      checkTypeListReport:[],
      ImageUrlRecheck:[],
      uploadListRecheck:[],
      nullCheck:true,
      correctionList:[],
      evaluationReportUrl:'',
      checklistOpinionCreateTimeArray:[],
      reportOpinionWorkFlowIdArray:[],
      opinionWorkFlowIdArray:[],
      stageDetailEndObj:{},
      stageDetailFirstObj:{},
      updateOpinion:false,
      updateOpinionValue:undefined,
      doubleClick:false,
      checkProblem:undefined,
    }
  },
  created () {
    this.utils = util
    let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : []
    this.buttonList = getButtonList(this.$route.path, routers)
    this.uploadFile = uploadFile
    this.getDataList()
    this.init()
  },
  computed:{

    headers(){
      return {
        "Authorization": 'Bearer ' + localStorage.getItem("Token")
      }
    }

  },
  mounted() {
    this.parseContent()
  },
  watch: {
    'addAcceptanceForm.projectName': function(newVal, oldVal) {
      // This will be triggered whenever projectName changes
      this.changeProjectObj();
    }
  },
  methods: {
    //创建工程项目
    createEngineer(){
      this.addAcceptanceForm={
        projectName:'',//工程名称
            ddIdProjectType:31,//工程类别
            provinceCode:undefined,//省
            cityCode:undefined,//市
            areaCode:undefined,//区
            address:'',//工程地址
            notify:1,//通知
            projectCompany:undefined,//项目申请单位
            firstDate:undefined,//初次验收日期
      },
      getCurrentDrContractList().then(res =>{
        this.companyIdList = res.data.response;
      })
      // console.log(localStorage.getItem("Token"),'token')
      this.dialog.createDialog.show = true;
    },
     addCreate(){
       // this.$refs.uploadFileR.submit();
      setTimeout(()=>{
        this.$refs['addAcceptanceForm'].validate(async(valid) => {
          let params = {
            provinceCode:this.changeAreaObj.provinceCode,//省
            cityCode:this.changeAreaObj.cityCode,//市
            areaCode:this.changeAreaObj.districtCode,//区
            contractId:this.changeAreaObj.id||undefined,//合同id
            customFailReportNo:undefined,//审查报告编号(不合格)
            customReportNo:undefined,//审查报告编号
            ddIdProjectType:this.addAcceptanceForm.ddIdProjectType,//工程类别
            fileList:this.uploadList,//
            firstDate:this.addAcceptanceForm.firstDate||undefined,//初次验收日期
            id:this.projectObj||undefined,//项目id
            projectAddress:this.addAcceptanceForm.address,//工程地址
            projectCompany:this.addAcceptanceForm.projectCompany,//项目申请单位
            projectName:this.addAcceptanceForm.projectName,//工程名称
          }
          const res = await addProjectAcceptance(params)
          // console.log(res,'res')
          if (res.data.status === 200){
            this.dialog.createDialog.show = false;
            this.dialogClose()
            this.$message({
              message:res.data.message,
              type:'success'
            })
          }else {
            this.$message({
              message:res.data.message,
              type:'error'
            })
          }})
      },0)
    },
    //编辑工程项目
    editEngineer(val){
      this.editQuestionForm.contentList[0].fileList = []
      // if (!this.needData) {
      //   this.$message({
      //     message: '请选择一条数据！',
      //     type: 'error'
      //   })
      //   return
      // }
      // if (this.needData.ddIdProjectStatus !== 254){
      //   this.$message({
      //     message: '已无法编辑编辑！',
      //     type: 'error'
      //   })
      //   return
      // }
      if(val.fileList && val.fileList.length > 0) {
        val.fileList.forEach(item => {
          item.url = item.fileUrl
          item.name = item.fileName
        })
      }
      this.editQuestionForm.contentList[0].fileList = val.fileList
      this.editAcceptanceForm={
        projectName:val.projectName,//工程名称
        ddIdProjectType:val.ddIdProjectType,//工程类别
        provinceCode:val.provinceCode,//省
        cityCode:val.cityCode,//市
        areaCode:val.areaCode,//区
        address:val.projectAddress,//工程地址
        notify:1,//通知
        projectCompany:val.projectCompany,//项目申请单位
        firstDate:val.firstDate,//初次验收日期
        fileList: this.editQuestionForm.contentList[0].fileList,
      }
          getCurrentDrContractList().then(res =>{
            this.companyIdList = res.data.response;
          })
      // console.log(localStorage.getItem("Token"),'token')
      this.dialog.createDialog.edit = true;
    },
    //保存编辑工程项目
    async editCreate(){
      let params = {
        projectName:this.needData.projectName,//工程名称
        ddIdProjectType:this.needData.ddIdProjectType,//工程类别
        provinceCode:this.needData.provinceCode,//省
        cityCode:this.needData.cityCode,//市
        areaCode:this.needData.areaCode,//区
        address:this.needData.projectAddress,//工程地址
        notify:1,//通知
        projectCompany:this.needData.projectCompany,//项目申请单位
        firstDate:this.needData.firstDate,//初次验收日期
        fileList: this.editQuestionForm.contentList[0].fileList,
        id:this.needData.id,
      }
      const res = await updateEngineerProject(params)
      if (res.data.success){
        this.$message({
          message:res.data.message,
          type:"success"
        })
        this.dialog.createDialog.edit = false
        this.getListData()
      }else {
        this.$message({
          message:res.data.message,
          type:"error"
        })
      }
    }
    ,
    //验收任务分工
    async acceptanceTask(){
      if (!this.needData) {
        this.$message({
          message: '请选择一条数据！',
          type: 'error'
        })
        return
      }
      if ((this.stageDetail&&this.stageDetail.length>1)&&(this.needData&&this.needData.acceptanceUserIds>0)&&this.stageDetailFirstObj.opinionWorkFlowId || this.stageDetailFirstObj.checklistOpinionWorkFlowId || this.stageDetailFirstObj.reportOpinionWorkFlowId){
        this.$message({
          message:'该项目已分配验收人员！',
          type:'error'
        })
        return;
      }
	    this.acceptanceTaskList = []
      this.addAcceptanceTask.person = []
      let params = {
      }
      const res = await getLocalExpertList(params)
      if (res.data.success){
        // console.log(res,'任务分工res')
	      if(this.needData && this.needData.acceptanceUserIds.length>0){
		      this.acceptanceTaskList = res.data.response.filter(item =>{
			      for (let i of this.needData.acceptanceUserIds) {
				      if (item.userInfoId === i) {
					      return false;
				      }
			      }
			      return true;
		      });
	      }else {
		      this.acceptanceTaskList = res.data.response
	      }
      }
      this.dialog.acceptanceDialog.show = true;
    },
    async addTaskPerson(){
      // console.log(this.addAcceptanceTask.person,'person');
      let params = {
        localExpertUserIds:this.addAcceptanceTask.person,
        projectId:this.needData.id,
      }
      // console.log(this.needData,'needData')
      if(!this.needData.acceptanceUserIds){
	      const res = await assignLocalExpertUsers(params)
	      if (res){
		      this.dialog.acceptanceDialog.show = false
		      this.addAcceptanceTask.person = []
		      this.dialogClose()
		      this.$message({
			      message:res.data.message,
			      type:'success'
		      })
	      }else{
		      this.$message({
			      message:res.data.message,
			      type:'error'
		      })
	      }
      }else {
	      const res = await appendLocalExpertUsers(params)
	      if (res){
		      this.dialog.acceptanceDialog.show = false
		      this.addAcceptanceTask.person = []
		      this.dialogClose()
		      this.$message({
			      message:res.data.message,
			      type:'success'
		      })
	      }else{
		      this.$message({
			      message:res.data.message,
			      type:'error'
		      })
	      }
      }
      // console.log(res,'restask')
    },
    //添加现场评定清单
    async addList(){
      if (!this.needData){
        this.$message({
          message:'请选择一条数据',
          type:'error'
        })
        return;
      }
      this.checkDialog = true;
      await this.getAcceptanceOpinion()

    },
        //1.下一步
    async nextStep(){
      console.log(this.checkTypeList,'logcheck');
      let params = {
        projectId:this.needData.id,
      }
      const res = await getCurrentOpinionList(params)
      console.log(res,'resgetCurrent')
      if (res.data.success){
        const right = this.checkTypeList.find(item=>item.optionValue===1)
        if (right){
          const arr = this.checkTypeList.filter(item=>item.optionValue === 1)
          console.log(arr,'arr')
          this.checkProjectName = arr.filter(item=>item.name)
          console.log(this.checkProjectName,'checkProjectName')
          this.dialog.addListDialog.nextStep = true;
          this.dialog.addListDialog.show = false;
        }else{
         await this.confirmList()
          this.dialog.addListDialog.show = false;
        }
      }else {
        this.$message({
          message:res.data.message,
          type:'error'
        })
      }

    },
          // 删除内容框card
    delContentInput (index) {
      if (index===0){
        this.addQuestionCard()
      }
      this.checkProjectName.splice(index,1)
    },
          //删除意见card
    async delOpinionCard(value){
      let params = {
        id:value.id
      }
      const res = await deleteOpinionAcceptance(params)
      console.log(res)
      if (res.data.success){
        this.$message({
          message:res.data.message,
          type:'success'
        })
        await this.updateList()
      }else{
        this.$message({
          message:res.data.message,
          type:'error'
        })
      }
    },
        // 添加内容框card
    addQuestionCard(){
        if (this.addQuestionForm.contentList&&this.addQuestionForm.contentList.length>=1){
          this.addQuestionForm.contentList.push({
            contentList:[
              {
                question:'',
                problemStatement:'',//问题表述
                violation:'',//违反规范条款
                photoList:[],//照片列表
              }
            ]
          },)
        }
    },
        // 删除问题card
    delQuestionCard(index){
      this.addQuestionForm.contentList.splice(index,1)
    },
    async addOpinionSave(index){
      let fileLists = this.addQuestionForm.contentList[0].fileList.map(item=>({
        fileName:item.response.response.sourceName,
        fileUrl:item.response.response.weburl,

      }))
      let params = {
        confirmProblem:this.addQuestionForm.contentList[0].problemStatement,//确认问题
        drQuestionId:this.questionVal.id,//问题库id
        drStandardId:this.questionVal.drStandardId,//所属规范id
        drStandardItemDescribe:`${this.questionVal.ddIdQuestionPropertieValue === '强' ? '违反' : '不符合'}《${this.questionVal.drStandardName}》${this.questionVal.drStandardItemSerialNumber}。`,//问题条文规范描述
        drStandardItemId:this.questionVal.drStandardItemId,//所属条文id
        fileList:fileLists,//
        id:undefined,//
        mainCheckType:this.questionValId.id,//主审查对象（字典命名空间：BizSevice.AcceptanceEngineering.ProjectMainCheckType）
        projectId:this.needData.id,//项目id
        specificOpinions:this.articlesForm.opinion,//问题描述
        subCheckType:this.addQuestionForm.contentList[0].question,//子审查对象（字典命名空间：BizSevice.AcceptanceEngineering.ProjectSubCheckType）
      }
      const res = await addOpinion(params)
      console.log(res,'resaddddddddddd')
      if (res.data.success){
        this.$message({
          message:res.data.message,
          type:'success'
        })
      }else{
        this.$message({
          message:res.data.message,
          type:'error'
        })
      }
      this.addQuestionCard()
      this.delQuestionCard(index)
      this.updateList()
      this.dialog.addListDialog.nextStep = true;
      this.dialog.addListDialog.addQuestion = false;

    },
    //更新数据
    async updateList(){
      let params1 = {
        projectId:this.needData.id,
      }
      const res1 = await getCurrentOpinionList(params1)
      if (res1.data.success) {
        console.log(res1.data.response, 'response')
        // this.checkTypeList = res.data.response.checkTypeList
        // this.opinionList = res.data.response.opinionList
        let check = res1.data.response.checkTypeList
        let opinion = res1.data.response.opinionList
        console.log(this.checkTypeList, 'checkTypeList')
        console.log(this.opinionList, 'opinionList')
        if (opinion) {
          let newArray = check.map(item1 => {
            item1.optionValue = 2
            opinion.forEach(item2 => {
              if (item2.mainCheckType === item1.id) {
                item1.optionValue = 1
              }
            });
            return item1
          });
          this.checkTypeList = newArray
          this.opinionList = opinion


          console.log(newArray, 'newArray111');
        } else {
          this.checkTypeList = check
        }
      }
    },
        //返回上一步
    returnStep(){
      this.dialog.addListDialog.nextStep = false;
      this.dialog.addListDialog.show = true;
    },
        //编辑
    confirmAddListEditDialog(value){

      this.listEditValue = value.confirmProblem
      // console.log('valueEdit',value.fileList)
      if(value.fileList && value.fileList.length > 0) {
        value.fileList.forEach(item => {
          item.url = item.fileUrl
          item.name = item.fileName
        })
      }
      // // this.addQuestionForm.contentList = [ value]
      this.editQuestionForm.contentList[0].question = value.subCheckTypeValue
      this.editQuestionForm.contentList[0].problemStatement = value.specificOpinions
      this.editQuestionForm.contentList[0].violation = value.drStandardItemDescribe
      this.editQuestionForm.contentList[0].fileList = value.fileList
      //     .map(item=>({
      //   sourceName:item.fileName,
      //   weburl:item.fileUrl,
      //   id:item.id,
      //   refId:72,
      //   refType:258,
      // }))
      this.dialog.addListDialog.editQuestion = true;
      // this.updateOpinion = true;
      this.updateOpinionValue = value;

    },
    checkListEdit(value){
      console.log(value)
      this.checkProblem = value.checklistProblem
      this.checkDialog =true
      this.dialog.addListDialog.isEdit = value.id;
    },
    async confirmAddListEdit(index){
      // console.log(value,isEdit,'vvvvvvvvvvvv')、
      let params = {
        confirmProblem: this.editQuestionForm.contentList[0].problemStatement,//确认问题描述
        fileList:this.editQuestionForm.contentList[0].fileList,//
        id:this.updateOpinionValue.id,
      }
      const res = await  updateOptions(params)
      if (res.data.success){
        this.$message({
          message:res.data.message,
          type:'success'
        })
      }
      this.dialog.addListDialog.isEdit = false;
      await this.updateList()
      this.dialog.addListDialog.nextStep = true;
      this.dialog.addListDialog.editQuestion = false;
    },
    async checkEditProblem(value){
      let params = {
        checklistProblem: this.checkProblem,//确认问题描述
        isChecklist:true,
        id:value.id,
      }
      const res = await  updateChecklistOptions(params)
      if (res.data.success){
        this.$message({
          message:res.data.message,
          type:'success'
        })
      }
      await this.updateCheckList();
      this.dialog.addListDialog.isEdit = false
    },
    //确认生成清单
    async confirmList(){
      this.dialog.addListDialog.addOpinion = true;
    },
    addOpinionClosed(){
      this.dialog.addListDialog.addOpinion = false;
      this.dialog.addListDialog.nextStep = false;
    },
    async addOpinionDialog(){
      let params ={
        projectId:this.needData.id
      }
      const res = await submitConfirmOpinion(params)
      if (res.data.success){
        this.$message({
          message:res.data.message,
          type:'success'
        })
        this.getListData()
        this.addOpinionClosed()

      }else {
        this.$message({
          message:res.data.message,
          type:'error'
        })
      }
    },
        //1.1添加问题
    async addQuestionList(value){
      console.log(value,'value1111')
      this.questionValId = value;
      this.subCheckTypeList = value.subCheckTypeList
      this.addQuestionForm.contentList[0].fileList=[]
      this.uploadList1=[]
      this.dialog.addListDialog.addQuestion = true;
      // this.dialog.addListDialog.nextStep = false;
    },
        //1.1.1添加条文
    articlesDialog(index){
      this.articlesIndex = index
      this.questionParsingArray = undefined
      this.questionListQuery = undefined
      this.articlesForm={
        query:undefined,//搜索
        createQuestion:undefined,//生成问题
        violationArticles:undefined,//违反条文
        opinion:'',//意见具体化
        opinionPreview:undefined,//意见预览
      }
      console.log(this.addQuestionForm.contentList);
      this.dialog.addListDialog.articles = true;
    },
    //添加条文确认
   async addTiaoWen() {
         await this.$nextTick()
        this.addQuestionForm.contentList[this.articlesIndex].problemStatement=this.articlesForm.opinion,
        this.addQuestionForm.contentList[this.articlesIndex].violation = `${this.questionVal.ddIdQuestionPropertieValue === '强' ? '违反' : '不符合'}《${this.questionVal.drStandardName}》${this.questionVal.drStandardItemSerialNumber}`

      // this.addQuestionForm.contentList[this.articlesIndex].problemStatement =
      this.dialog.addListDialog.articles = false;
    },

    handleSelectChange(value) {
      // 选项框change
      this.questionPush = ''
      if (this.questionVal.questionDescription){
        this.questionParsingArray.forEach((item,index)=>{
          if (item.includes('{')&&(this.typeCheck.length===1||index<=1)){
            this.questionPush +=this.articlesForm.createQuestion2?this.articlesForm.createQuestion2.join('、'):undefined
          }
          if (item.includes('{')&&(this.typeCheck.length>1&&index>1)){
            this.questionPush +=this.articlesForm.createQuestion3?this.articlesForm.createQuestion3.join('、'):undefined
          }
          if (item.includes('（')&&(this.typeRadio.length===1||index<=1)){
            this.questionPush += this.articlesForm.createQuestion?this.articlesForm.createQuestion:undefined
          }
          if (item.includes('（')&&(this.typeRadio.length>1&&index>1)){
            this.questionPush += this.articlesForm.createQuestion1?this.articlesForm.createQuestion1:undefined
          }
          if (!item.includes('{')&&!item.includes('（')){
            this.questionPush  += item
          }
        })
      }else{
        this.questionPush = this.questionVal.questionDescription
      }
      this.articlesForm.opinion = this.questionPush
      this.opinionPreviewMe()
      console.log('Selected values:', value);
    },
        //1.1.1.1问题库查询
    async questionQuery(){
      this.standardList =[]
      this.questionVal.questionOptions = undefined
      this.questionQueryForm.specification= undefined
      const res = await getDrStandardList();
      console.log(res,'result111')
      if (res.data.success) {
          const {data} = res.data.response;
          const all = {
            label: '全部',
            value: 0,
            id: 0,
          };
          const list = data.map((item) => ({
            label: item.name,
            value: item.id,
            id: item.id,
          }));
          this.standardList.push([all, ...list]);
          console.log(this.standardList,'this.stand')

      }
      this.dialog.addListDialog.questionQueryDialog = true;
    },
    //意见预览
    opinionPreviewMe(){
      if (this.questionVal.questionOptions){
        this.articlesForm.opinionPreview =
            `${this.questionPush},不符合《${this.questionVal.drStandardName}》${this.questionVal.drStandardBn}(${this.questionVal.drStandardVersion})${this.questionVal.drStandardItemSerialNumber}
            问题属性：${this.questionVal.ddIdQuestionPropertieValue}
            问题分类：${this.questionVal.ddIdQuestionTypeValue}`
      }else{
        this.articlesForm.opinionPreview =
            `${this.questionVal.questionDescription}， ${this.questionVal.ddIdQuestionPropertieValue === '强' ? '违反' : '不符合'}《${this.questionVal.drStandardName}》${this.questionVal.drStandardBn}(${this.questionVal.drStandardVersion})${this.questionVal.drStandardItemSerialNumber}
            问题属性：${this.questionVal.ddIdQuestionPropertieValue}
            问题分类：${this.questionVal.ddIdQuestionTypeValue}`
      }
    },
          //问题库查询确认
    questionQueryConfirm(){
      this.typeCheck =[]
      this.typeRadio =[]
      this.questionParsingArray = []
      // this.questionVal
      let violationArticles = `《${this.questionVal.drStandardName}》${this.questionVal.drStandardItemSerialNumber}`;
      if (this.questionVal.questionOptions){
        let questionOptions = JSON.parse(this.questionVal.questionOptions);
        let check = questionOptions.filter(item=>item.type === 'check')
        let radio = questionOptions.filter(item=>item.type === 'radio')
        this.typeCheck = check.map(item=>item.options)
        this.typeRadio = radio.map(item=>item.options)
        console.log(this.typeRadio,'this.typeRadio')
        console.log(this.typeCheck,'this.typeCheck')
      }else {
        this.typeCheck = []
        this.typeRadio = []
      }
      // 输入的字符串
      var inputString = this.questionVal.questionDescription;
      var inputString1 = this.questionVal.questionParsing;
      // 使用正则表达式分割并保留大括号及其内容
      const regex =/(\【[^\】]*\】)|(\（[^\）]*\）)|(\{[^\}]*\})/g;
      const regex1 = /(\{[^\}]*\})/g;
      const resultArray = inputString.split(regex).filter(str => str !== undefined&&str!=='');
      const resultArray1 = inputString1.split(regex1).filter(str => str !== "");

      console.log(this.questionPush,'push')
      console.log(resultArray1,'resultArray11111');
      console.log(resultArray,'resultArray111112222');
      this.questionParsingArray = resultArray
      this.questionArray = resultArray1

      console.log(this.questionPush,'push')
      this.articlesForm={
        query:this.questionVal.questionDescription,//搜索
        // createQuestion:undefined,//生成问题
        violationArticles:violationArticles,//违反条文
        opinionPreview:undefined,//意见预览
        opinion:this.questionVal.questionDescription,//意见具体化
      }
      this.opinionPreviewMe()
      this.dialog.addListDialog.questionQueryDialog = false

    },
    handleBlur() {
      console.log("输入框失去焦点");
      // 在这里调用你需要的方法
      this.getQuestion()
    },
    parseContent() {
      const regex = /(\([^\)]*\)|\{[^\}]*\})/g;
      let match;
      while ((match = regex.exec(this.questionVal.questionDescription)) !== null) {
        // 将匹配到的内容添加到选项数组
        this.resultArray.push({
          label: match[0].substring(1, match[0].length - 1),
          value: match[0].substring(1, match[0].length - 1),
        });
      }
    },
          //问题库列表
    async specificationVal(val){
      this.standardId = val
      await this.getQuestion()
    },
   async getQuestion(){
      this.questionListQuery= []
      // console.log(val,'val222')
      let params = {
        auditContentWord:undefined,//审查内容查询
        ddIdNodeBizType:10,//业务模块类型（字典命名空间：CommonService.WorkFlow.NodeBizType）
        keyword:this.questionQueryForm.questionName||undefined,//问题编号或描述关键字
        pageSize:20,//
        pageIndex:1,//
        questionId:undefined,//问题ID
        questionPropertie:undefined,//问题属性
        standardId:this.standardId||undefined,//规范id
        standardItemId:undefined,//规范条文id
        standardType:undefined,//规范类型
	      sourceType:'web',
      }
      const res = await getAcceptanceQuestionPageList(params)
      console.log(res,'resq')
      if (res.data.success){
        const response = res.data.response
        const data = response.data
        this.questionListQuery = data
        console.log(data,'data2222')
      }
    },
    handleMouseLeave() {
      // 隐藏弹窗
      this.dialogVisibleTooltip = false;
    },
    currentChangeQuestion(val){
      if (val){
        this.questionVal = val
      }
      console.log(val ,'questionval')
    },
    //确认现场评定清单
    async confirmCheckList(){
      if (!this.needData){
        this.$message({
          message:'请选择一条数据！',
          type:'error'
        })
        return;
      }
     await this.checkingDialogEdit()
      this.opinionList = []
      this.checkTypeList = []
      // const obj = this.stageDetail.pop()
      await this.updateCheckList()
    },
    async updateCheckList(){
      let params = {
        projectId:this.needData.id,//
      }
      const res = await getCurrentChecklistOpinionList(params)

      // const res = await getOpinionListByWorkFlowIdAcceptance(params)
      console.log(res,'confirmCheckList')
      if (res.data.success){
        const data = res.data.response
        this.opinionList = data.opinionList
        this.checkTypeList = data.checkTypeList
        this.dialog.confirmCheckList.show = true;
      }else {
        this.$message({
          message:res.data.message,
          type:'error'
        })
      }
    },
    //阅卷
    async confirmCheckListPost() {
      try {
        // 使用 Element UI 提供的 $confirm 方法创建确认弹窗
        const userConfirmed = await this.$confirm('确定要生成检查清单吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });
        // this.spliceList.forEach(value=>{
        //   this.delOpinionCard(value)
        // })
        if (this.doubleClick){
          this.$message({
            message:'请不要多次点击！',
            type:'warning'
          })
          return
        }
        this.doubleClick = true;
        if (userConfirmed) {
          let params = {
            projectId: this.needData.id
          };
          const res = await generateCheckList(params);
          console.log(res, 'generateCheckList');
          if (res.data.success){
            this.$message({
              message:res.data.message,
              type:'success'
            })
            this.dialog.confirmCheckList.show = false;
            this.getListData()
            this.doubleClick = false;
          }
          // 在这里可以执行其他后续操作
        } else {
          // 用户点击取消，可以选择执行一些其他操作或者直接返回
          console.log('用户取消了生成检查清单操作');
          this.doubleClick = false;
        }
      } catch (error) {
        console.error('生成检查清单时发生错误:', error);
        this.doubleClick = false;
        // 处理错误情况
      }
    },

    //添加验收评定报告
    async addReport(){
      if (!this.needData){
        this.$message({
          message:'请选择一条数据',
          type:'error'
        })
        return;
      }
      if (this.doubleClick){
        this.$message({
          message:'请不要多次点击！',
          type:'warning'
        })
        return
      }
      this.doubleClick = true;
      // if (this.stageDetailEndObj.opinionWorkFlowId && this.stageDetailEndObj.checklistOpinionWorkFlowId &&!this.stageDetailEndObj.reportOpinionWorkFlowId){
      //   this.$message({
      //     message:'验收流程未完成或已添加复验！',
      //     type:'error'
      //   })
      //   return;
      // }
      this.reportForm={
        projectName:undefined,//项目名
            projectCompany:undefined,//申请公司
            projectAddress:undefined,//项目地址
            buildStorey:undefined,//建筑层数
            buildHeight:undefined,//建筑高度
            buildArea:undefined,//建筑面积
            natureOfUse:undefined,//使用性质
            applyProjectLocation:undefined,//申请项目位置
            applyBuildArea:undefined,//申请建筑面积
            applyNatureOfUse:undefined,//申请使用性质
            projectNotes:undefined,//
            optionValue:2,
      }
      this.reportBoolean = true
      this.reportA=false;
      this.reportB=false;
      this.reportC=false;
      this.reportD=false;
      this.reportE=false;
      this.reportF=false;
      this.reportG=false;
      this.reportH=false;
      this.reportI=false;
      this.reportJ=false;
      let params = {
        projectId:this.needData.id
      }
      const res = await getCurrentReportInfo(params)
      console.log(res,'addReport')

      if (res.data.success){
        const response = res.data.response
        this.docxUrl = response.checklistFileUrl
        this.opinionListReport =[ ...response.opinionList];

        console.log(response.opinionList,'ooooooooooooooo')
        this.checkTypeListReport = [...response.checkTypeList];
        const projectInfo = response.projectInfo
        this.reportForm={
          projectName:projectInfo.projectName,//项目名
          projectCompany:projectInfo.projectCompany,//申请公司
          projectAddress:projectInfo.projectAddress,//项目地址
          buildStorey:projectInfo.buildStorey,//建筑层数
          buildHeight:projectInfo.buildHeight,//建筑高度
          buildArea:projectInfo.buildArea,//建筑面积
          natureOfUse:projectInfo.natureOfUse,//使用性质
          applyProjectLocation:projectInfo.applyProjectLocation,//申请项目位置
          applyBuildArea:projectInfo.applyBuildArea,//申请建筑面积
          applyNatureOfUse:projectInfo.applyNatureOfUse,//申请使用性质
          projectNotes:undefined,//
          optionValue:2,
        }
        if (response&&response.opinionList.length>0){
          this.reportForm.optionValue = 1
        }else {
          this.reportForm.optionValue = 2
        }
        if (projectInfo.projectName){
          this.reportJ = true
        }
        if (projectInfo.projectCompany){
          this.reportA = true
        }
        if (projectInfo.projectAddress){
          this.reportB = true
        }
        if (projectInfo.buildStorey){
          this.reportC = true
        }
        if (projectInfo.buildHeight){
          this.reportD = true
        }
        if (projectInfo.buildArea){
          this.reportE = true
        }
        if (projectInfo.natureOfUse){
          this.reportF = true
        }
        if (projectInfo.applyProjectLocation){
          this.reportG = true
        }
        if (projectInfo.applyBuildArea){
          this.reportH = true
        }
        if (projectInfo.applyNatureOfUse){
          this.reportI = true
        }
        // if (response.opinionList){
        //   this.reportForm.optionValue =1
        // }
        this.checkDialogisEdit = true;
        this.dialog.addReportDialog.show = true;
        this.doubleClick = false;
      }
      else {
        this.$message({
          message:'请先确认现场评定清单或评定报告已生成！',
          type:'error'
        })
        this.doubleClick = false;
      }



    },
    //编辑reportCard
    reportCardEdit(value){
      this.reportEditValue = value.reportProblem
      this.dialog.addReportDialog.isEdit = true;
    },
    //report编辑更新
    async confirmReportEdit(value){

      let params = {
        id:value.id,//意见id
        isReport:true,//是否报告中
        reportProblem:this.reportEditValue,//报告问题描述
      }
      const res = await updateReportOptions(params);//修改报告意见
      this.dialog.addReportDialog.isEdit = false;
      console.log(res,'resupdate')
      let params1 = {
        projectId:this.needData.id
      }
      const res1 = await getCurrentReportInfo(params1)
      console.log(res,'addddddddddddddddddd')

      if (res1.data.success) {
        const response = res1.data.response
        this.docxUrl = response.checklistFileUrl
        this.opinionListReport = [...response.opinionList];
        console.log(response.opinionList, 'ooooooooooooooo')
        this.checkTypeListReport = [...response.checkTypeList];
      }
    },
    async confirmAddCheckList(){
      this.$refs.reportFormRef.validate().then(valid => {
        if (valid) {
          // 表单验证通过，执行相应的逻辑
          this.dialog.addReportDialog.addOpinion = true;
        } else {
          // 表单验证失败，可以给出相应的提示或者处理
        }
      });

    },
    //确认生成报告
    addReportDialog(){
      if (this.doubleClick){
        this.$message({
          message:'请不要多次点击！',
          type:'warning'
        })
        return
      }
      this.doubleClick = true;
      this.$refs.reportFormRef.validate(async (validate)=> {
        if (validate){
          // this.spliceList1.forEach( value=>{
          //   let params = {
          //     id:value.id,//意见id
          //     isReport:false,//是否报告中
          //     reportProblem:value.confirmProblem,//报告问题描述
          //   }
          //    updateReportOptions(params).then(res=>{
          //     console.log(res)
          //   });//修改报告意见
          // })

          let info = this.reportForm
          let param = {
            customReportNo:this.addReportOpinion.customReportNo,//报告编号自定义
            projectId:this.needData.id,//
            projectInfo:info,//
            projectNotes:this.addReportOpinion.projectNotes,//项目备注
          }
          const res1 = await generateReport(param)
          if (res1.data.success){
            this.$message({
              message:res1.data.message,
              type:'success'
            })
            this.doubleClick = false;
            this.addReportClosed()
          }else {
            this.$message({
              message:res1.data.message,
              type:'error'
            })
            this.doubleClick = false;
          }
          console.log(res1,'res1')
        }
      })
    },
    addReportClosed(){
      this.dialog.addReportDialog.show = false;
      this.dialog.addReportDialog.addOpinion = false;
      this.getListData()
    },
    //添加复验
    addRetest(){
      if (!this.needData){
        this.$message({
          message:'请选择一条数据！',
          type:'error'
        })
        return;
      }
      // let stagePop = this.stageDetail
      // let pop = stagePop.pop()
      // if (pop.reportOpinionWorkFlowId&&pop.opinionWorkFlowId&&pop.checklistOpinionWorkFlowId){
      //   this.$message({
      //     message:'验收流程未完成或已添加复验！',
      //     type:'error'
      //   })
      //   return;
      // }
      if (this.stageDetail&&this.stageDetail.length>1){
        if (this.stageDetailEndObj.opinionWorkFlowId && this.stageDetailEndObj.checklistOpinionWorkFlowId &&this.stageDetailEndObj.reportOpinionWorkFlowId){
          this.$message({
            message:'验收报告已合格！',
            type:'success'
          })
          return;
        }

        if (this.stageDetailEndObj.opinionWorkFlowId || this.stageDetailEndObj.checklistOpinionWorkFlowId ||this.stageDetailEndObj.reportOpinionWorkFlowId){
          this.$message({
            message:'验收流程未完成或已添加复验！',
            type:'error'
          })
          return;
        }
      }
      this.recheckForm.addTime = this.showNowTime()
      this.dialog.addRetestDialog.show = true;
    },
    async addRecheckConfirm(){
      if (this.doubleClick){
        this.$message({
          message:'请不要多次点击！',
          type:'warning'
        })
        return
      }
      this.doubleClick = true;
      let params = {
        projectId:this.needData.id,
        reviewDate:this.recheckForm.addTime,
        fileList:this.uploadListRecheck
      }
      const res = await addReviewTask(params)
      console.log(res,'addReviewTask-添加复验')
      if (res.data.success){
        this.$message({
          message:res.data.message,
          type:'success'
        })
        this.addRecheckClosed()
        this.doubleClick = false;
      } else {
        this.$message({
          message:res.data.message,
          type:'error'
        })
        this.doubleClick = false;
      }
    },
    addRecheckClosed(){
      this.dialog.addRetestDialog.show = false;
      this.ImageUrlRecheck = []
      this.getListData()
    },


    //项目详情查看
    projectDetailCheck(val){
      // this.projectDetailForm={
      //   projectName:val.projectName,//工程名称
      //   ddIdProjectType:val.ddIdProjectType,//工程类别
      //   provinceCode:val.provinceCodeValue,//省
      //   cityCode:val.cityCodeValue,//市
      //   areaCode:val.areaCodeValue,//区
      //   address:val.projectAddress,//工程地址
      //   notify:1,//通知
      //   projectCompany:val.projectCompany,//项目申请单位
      //   firstDate:val.firstDate,//初次验收日期
      //   fileList: val.fileList
      // }
      this.editEngineer(val)
      // this.dialog.projectDetailDialog.show =true;
    },
    //现场评定清单查看
    async checkListDialog(val){
      this.dialog.listDialog.show = true
    },
    //现场评定清单阶段编辑
    async stageChecklistEdit(value){
      this.checkTypeList =[]
      this.opinionList = []
      this.checkDialog= true;
      console.log(value,'编辑')
      let params = {
        projectId:this.needData.id,//
      }
      // const res = await getChecklistOpinionListByWorkFlowId(params)
      const res = await getCurrentOpinionList(params)
      console.log(res,'rescheckID')
      if (res.data.success){
        const data = res.data.response
        const opinion = data.opinionList
        const check = data.checkTypeList
        if (opinion){
          let newArray = check.map(item1 => {
            item1.optionValue = 2
            opinion.forEach(item2 => {
              if (item2.mainCheckType===item1.id){
                item1.optionValue =1
              }
            });
            return item1
          });
          this.checkTypeList = newArray
          this.opinionList = opinion

          console.log(newArray,'newArray111');
        }else {
          let newArray = check.map(item=>{
            item.optionValue = 2
          })
          this.checkTypeList = newArray;

        }
        this.dialog.addListDialog.nextStep = true;
      }else {
        this.$message({
          message:'该清单未生成',
          type:'error'
        })
      }
    },
    //现场评定清单阶段查看
    async stageChecklistCheck(value){
      this.checkTypeList =[]
      this.opinionList = []
      console.log(value,'编辑')
      let params = {
        projectId:this.needData.id,//
        workFlowId: value.opinionWorkFlowId,
      }
      // const res = await getChecklistOpinionListByWorkFlowId(params)
      const res = await getOpinionListByWorkFlowIdAcceptance(params)
      console.log(res,'rescheckID')
      if (res.data.success){
        const data = res.data.response
        const opinion = data.opinionList
        const check = data.checkTypeList
        if (opinion){
          let newArray = check.map(item1 => {
            item1.optionValue = 2
            opinion.forEach(item2 => {
              if (item2.mainCheckType===item1.id){
                item1.optionValue =1
              }
            });
            return item1
          });
          this.checkTypeList = newArray
          this.opinionList = opinion

          console.log(newArray,'newArray111');
        }else {
          let newArray = check.map(item=>{
            item.optionValue = 2
          })
          this.checkTypeList = newArray;

        }
        this.dialog.addListDialog.nextStep = true;
      }else {
        this.$message({
          message:'该清单未生成',
          type:'error'
        })
        return
      }
      this.checkDialog = false;
    },
    //现场评定抽选检查查看
    checkCheckingDialog(){
      this.dialog.checkDialog.show = true;
    },
    async checkingDialog(value){
      this.checkTypeList = []
      this.opinionList = []
      console.log(value,'checkindDialog')
      let params = {
        projectId:this.needData.id,
        workFlowId: value.checklistOpinionWorkFlowId,
      }
      const res = await getChecklistOpinionListByWorkFlowId(params)
      console.log(res,'getChecklistOpinionListByWorkFlowId')
      if (!res.data.response){
        this.$message({
          message:'无评定检查清单！',
          type:'error'
        })
        this.nullCheck = false;
        return;
      }
      if (res.data.success){

        let check = res.data.response.checkTypeList
        let opinion = res.data.response.opinionList
        console.log(this.checkTypeList,'checkTypeList')
        console.log(this.opinionList,'opinionList')

        if (opinion){
          let newArray = check.map(item1 => {
            item1.optionValue = 2
            opinion.forEach(item2 => {
              if (item2.mainCheckType===item1.id){
                item1.optionValue =1
              }
            });
            return item1
          });
          this.checkTypeList = newArray
          this.opinionList = opinion
          if (this.opinionList){
            this.radioChange = 2
          }else {
            this.radioChange =1
          }
          console.log(newArray,'newArray111');
        }else {
          this.checkTypeList = check
        }
      }
      this.checkDialogisEdit = true;
      this.dialog.checkDialog.checking = true ;
    },
    async checkingDialogEdit(value){
      this.checkTypeList = []
      this.opinionList = []
      console.log(value,'checkindDialog')
      let params = {
        projectId:this.needData.id,
      }
      const res = await getCurrentChecklistOpinionList(params)
      console.log(res,'getChecklistOpinionListByWorkFlowId')
      // if (!res.data.response){
      //   this.$message({
      //     message:'负责人没有确认现场评定清单！',
      //     type:'error'
      //   })
      //   this.nullCheck = false;
      //   return;
      // }
      // if (!value.checklistOpinionIsEdit){
      //   this.$message({
      //     message:'其他验收人员没有-确认生成清单！',
      //     type:'error'
      //   })
      //   this.nullCheck = false;
      //   return;
      // }
      if (res.data.success){
        let check = res.data.response.checkTypeList
        let opinion = res.data.response.opinionList
        console.log(this.checkTypeList,'checkTypeList')
        console.log(this.opinionList,'opinionList')

        if (opinion){
          let newArray = check.map(item1 => {
            item1.optionValue = 2
            opinion.forEach(item2 => {
              if (item2.mainCheckType===item1.id){
                item1.optionValue =1
              }
            });
            return item1
          });
          this.checkTypeList = newArray
          this.opinionList = opinion

          console.log(newArray,'newArray111');
        }else {
          this.checkTypeList = check
        }
      }
      // this.checkDialogisEdit = true;
      // this.dialog.checkDialog.checking = true ;
    },
    async spliceCard(value){
      let res  = this.opinionList.splice(value,1)
      console.log(value,res,'jjjjjjjjjjjjjjjjj')
      this.spliceList.push(...res)
      let params = {
        isChecklist:false,
        id:res[0].id,
        checklistProblem:res[0].checklistProblem,
      }
      await updateChecklistOptions(params)
    },
    async spliceCardAll(value){
      let res  = this.spliceList.splice(value-1000,1)
      this.opinionList = [...this.opinionList,...res]
      let params = {
        isChecklist:true,
        id:res[0].id,
        checklistProblem:res[0].checklistProblem,
      }
      await updateChecklistOptions(params)
    },
    async spliceCard1(value){
      let res  = this.opinionListReport.splice(value,1)
      console.log(value,res,'jjjjjjjjjjjjjjjjj')
      if (this.opinionListReport&&this.opinionListReport.length>0){
        this.reportForm.optionValue = 1
      }else{
        this.reportForm.optionValue = 2
      }
      this.spliceList1.push(...res)
      let params = {
        id:res[0].id,
        isReport:false,
        reportProblem:res[0].reportProblem
      }
      await updateReportOptions(params)
    },
    async spliceCardAll1(value){
      let res  = this.spliceList1.splice(value-1000,1)
      this.opinionListReport = [...this.opinionListReport,...res]
      if (this.opinionListReport&&this.opinionListReport.length>0){
        this.reportForm.optionValue = 1
      }else{
        this.reportForm.optionValue = 2
      }
      let params = {
        id:res[0].id,
        isReport:true,
        reportProblem:res[0].reportProblem
      }
      await updateReportOptions(params)
    },
    //整改报告查看
    async reportChecking(){
      this.dialog.reportDialog.show = true;
    },
    //整改报告查看列表中的上传
    async correctionReportEdit(val){

    },
    //整改报告查看列表中的查看
    async correctionReportCheck(){

    },
    //验收评定报告查看
    reportCheck(){
      this.dialog.accReportDialog.show = true;
    },
    async evaluationReportCheck(val){
      // let params = {
      //   projectId:this.needData.id,
      //   workFlowId:val.reportOpinionWorkFlowId,
      // }
      // const res = await reportOpinionWorkFlowId(params)
      // console.log(res,'resportOpinionWorkFlowId')
      this.evaluationReportUrl = val.reportFileUrl
      this.dialog.accReportDialog.check = true;
    },
    //项目阶段查看
    stageProject(){
      this.dialog.addListDialog.nextStep = true;
    },
    stageProjectDetail(){
      this.dialog.stageProjectDialog.show = true;
    },
    handleRemove(file, fileList) {
      this.fileList = fileList
      // console.log(this.fileList,'2222')
    },
    handlePreview(file) {
      // console.log(file,'111');
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleChange(file, fileList) {
      this.fileList=fileList
      // console.log(this.fileList,'33333')
    },
    submitUpload() {
      this.$refs.uploadFileR.submit();
    },
    handleRemove1(file, fileList,index) {
      this.addQuestionForm.contentList[index].fileList = fileList
      console.log(file,fileList,index,'2222')
    },
    handleRemoveIsedit(file, fileList,index) {
      this.editQuestionForm.contentList[0].fileList = fileList
      console.log(file,fileList,index,'22221')
    },
    handleRemoveRecheck(file, fileList) {
      this.ImageUrlRecheck = fileList;
    },
    handlePreview1(file) {
      console.log(file,'111');
      this.dialogImageUrl1 = file.url;
      this.dialogVisible1 = true;
    },
    downloadFile(){
      return this.dialogImageUrl1
    },
    handlePreviewIsedit(file) {
      console.log(file,'111');
      this.dialogImageUrl1 = file.url;
      this.dialogVisibleEdit = true;
    },
    handlePreviewRecheck(file) {
      // console.log(file,'111');
      this.ImageUrlRecheck = file.url;
      this.dialog.addRetestDialog.photo = true;
    },
    handleChange1(file, fileList ,index) {
      console.log(file,fileList,index,'fieldkkdkdkdkdk')
      this.addQuestionForm.contentList[index].fileList=fileList
      // console.log(this.fileList,'33333')
    },
    handleChangeEdit(file, fileList ,index) {
      console.log(file,fileList,index,'fieldkkdkdkdkdk111')
      // const indexArray = fileList.findIndex(obj =>{
      //   if (obj&&obj.uid){
      //     obj.uid === file.uid
      //   }
      // });
      // if (indexArray !== -1) {
        if(file&&file.response){
          fileList[fileList.length-1] = {
            fileName: file.name,
            fileUrl: file.response.response.weburl,
            name: file.name,
            url: file.url,


          }
          this.editQuestionForm.contentList[0].fileList=fileList
        }else{
          this.editQuestionForm.contentList[0].fileList=fileList
        }
      console.log(this.editQuestionForm.contentList[0].fileList,'this.editQuestionForm.contentList[0].fileList')



      // console.log(this.fileList,'33333')
    },
    handleChangeRecheck(file, fileList ) {
      this.ImageUrlRecheck = fileList
    },
    submitUpload1(index) {
      // 使用动态的ref来获取上传组件实例
      const uploadComponent = this.$refs.uploadFileRef[index];

      // Manually trigger the upload
      uploadComponent.uploadFiles();
    },
    changeSelectArea(val){
     if (val){
       // console.log(val,'changeVal')
       const obj = this.companyIdList.find(item=>item.id === val)
       this.changeAreaObj = obj
       // console.log(obj,'obj')
       let params = {
         areaCode:obj.districtCode,
         pageIndex:1,
         pageSize:20,
       }
       getAllProjectList(params).then(res =>{
         if (res.data.success){
           this.changeProjectList = res.data.response.data
         }
       })
     }
    },
    async changeProjectObj(){
      // console.log(val)
     // if (val){
       const obj = this.changeProjectList.find(item=>item.projectName === this.addAcceptanceForm.projectName)
      this.projectObj = undefined
       if (obj&&obj.id){
         let params = {
           projectId:obj.id,
         }
         const res = await getProjectApplicationByProjectId(params)
         if (res.data.success){
           this.addAcceptanceForm.projectCompany = res.data.response.contractor;
           this.addAcceptanceForm.address = res.data.response.address;
         }
         this.projectObj = obj.id
       }else {
         // this.addAcceptanceForm={
         //   // projectName:'',//工程名称
         //   // ddIdProjectType:31,//工程类别
         //   // provinceCode:undefined,//省
         //   // cityCode:undefined,//市
         //   // areaCode:undefined,//区
         //   address:'',//工程地址
         //   notify:1,//通知
         //   projectCompany:undefined,//项目申请单位
         //   firstDate:undefined,//初次验收日期
         // }
         this.addAcceptanceForm.address = '';
         // this.addAcceptanceForm.notify = 1;
         this.addAcceptanceForm.projectCompany = undefined;
         this.addAcceptanceForm.firstDate = undefined;
         this.fileList = [];
       }


       console.log(obj,'projectObj')
     // }

    },
    getCurrentDate() {
      const currentDate = new Date();
      this.currentYear = currentDate.getFullYear();
      this.currentMonth = currentDate.getMonth() + 1; // 月份从 0 到 11，所以要加 1
    },
    handle_success (res) {
      // console.log(res)
      if (res.success) {
        // console.log(res,'successRes')
        const result = {
          fileName:res.response.sourceName,
          fileUrl:res.response.weburl,
          refId:undefined,

        }
        this.uploadList.push(result)
      }
    },
    handle_success1 (res) {
      // console.log(res)
      if (res.success) {
        // console.log(res,'successRes')
        const result = {
          fileName:res.response.sourceName,
          fileUrl:res.response.weburl,
          refId:undefined,

        }
        this.uploadList1.push(result)
      }
      console.log(this.uploadList1,'up1');
    },
    handle_success_edit (res) {
      // console.log(res)
      if (res.success) {
        // console.log(res,'successRes')
        const result = {
          fileName:res.response.sourceName,
          fileUrl:res.response.weburl,
          refId:undefined,

        }
        this.uploadListEdit.push(result)
      }
      console.log(this.uploadListEdit,'up1');
    },
    handleSuccessRecheck (res) {
      // console.log(res)
      if (res.success) {
        // console.log(res,'successRes')
        const result = {
          fileName:res.response.sourceName,
          fileUrl:res.response.weburl,
          refId:undefined,
        }
        this.uploadListRecheck.push(result)
      }
      console.log(this.uploadListRecheck,'up1');
    },

    dialogClose(){
      this.uploadList = [];
      this.fileList = []
      this.changeAreaObj = undefined;
      this.getListData()
    },
    //显示当前时间
    showNowTime(){
      var currentDateTime = new Date();
      var year = currentDateTime.getFullYear();
      var month = currentDateTime.getMonth() + 1; // 月份从0开始，因此需要加1
      var day = currentDateTime.getDate();
      var hours = currentDateTime.getHours();
      var minutes = currentDateTime.getMinutes();
      var seconds = currentDateTime.getSeconds();

      // 添加零以保持两位数格式
      month = (month < 10 ? "0" : "") + month;
      day = (day < 10 ? "0" : "") + day;
      hours = (hours < 10 ? "0" : "") + hours;
      minutes = (minutes < 10 ? "0" : "") + minutes;
      seconds = (seconds < 10 ? "0" : "") + seconds;

      // 根据需要，可以修改日期时间的显示格式
      var dateTimeString = year + "-" + month + "-" + day
          // + " " + hours + ":" + minutes + ":" + seconds;
      return dateTimeString
    },















    callFunction (item) {
      this[item.func].apply(this, item)
    },

    init () {
      // 获取省份
      getAreaDictionaryList({ parentId: 1 }).then(res => {
        this.provinceSearchOptions = res.data.response.reverse()
      })
    },
    // 查询
    getListData () {
      this.pages.pageIndex = 1 ;
      this.pages.pageSize = 20 ;
      this.getDataList();

    },
    async nameSpace(){
      let params = {
        namespace:'BizSevice.AcceptanceEngineering.ProjectSubCheckType'
      }
      const res = await getByNamespace(params)
      const data = res.data.response
      console.log(data,'dataaaa')
    },
    // 列表查询接口
    getDataList () {
      let params = {
        areaCode:undefined,//区
        cityCode:undefined,//市
        firstDate:this.pickerData,//验收开始时间
        projectName:this.filters.name,//工程名称
        projectStatus:this.filters.expertType,//状态
        provinceCode:this.filters.provinceCode,//省份
        pageSize:this.pages.pageSize,
        pageIndex:this.pages.pageIndex,
      }
      getAcceptanceProjectList(params).then(res => {
        const result = res.data
        // console.log(res,'res')
        if (result.success) {
          this.tableData = result.response.data.filter(item=>item.ddIdProjectStatus !== 256)
          this.dataCount = result.response.dataCount
        }
      })
      this.nameSpace();
    },
    // 分页
    handleCurrentChange (val) {
      this.pages.pageIndex = val;
      console.log(val,'valindex')
      this.getDataList();
    },
    handleSizeChange (val) {
      console.log(val,'valsize')
      this.pages.pageSize = val;
      this.getDataList();
    },
    // 表格数据分页条数累计
    indexMethod(index) {
      let currentPage = this.pages.pageIndex
      let pageSize = this.pages.pageSize
      return (index + 1) + (currentPage - 1) * pageSize
    },
    // 点击table某一行
    async currentChange (val) {
      if (val){
        this.needData = val
        console.log(val,'val')
        await this.stageDetailes()
      }
    },
   stageDetailes(){
    let params = {
      projectId:this.needData.id
    }
    console.log(params,'params')
     getAcceptanceByProjectId(params).then(res=>{
       console.log(res,'ressssssss')
        this.datePart(res)
     })
    },
    datePart(res){
      if (res.data.success){
          this.stageDetail = res.data.response

          // 使用 filter 方法过滤出 fileList 不为空的对象
          var newArray = this.stageDetail.filter((obj)=> {
            return obj.stageName === '复验';
          });
          this.rectify = newArray
          this.opinionWorkFlowIdArray = this.stageDetail.filter( (obj)=>{
            return obj.opinionWorkFlowId
          })
          console.log(this.stageDetail,'detail')
          console.log(this.opinionWorkFlowIdArray,'arrassssss')
          this.checklistOpinionCreateTimeArray	 = this.stageDetail.filter((obj)=> {
            return obj.checklistOpinionCreateTime	 ;
          });
          // this.checklistOpinionCreateTimeArray	 = this.stageDetail.filter(function(obj) {
          //   return obj.checklistOpinionCreateTime
          // });
          this.reportOpinionWorkFlowIdArray = this.stageDetail.filter( (obj)=>{
            return obj.reportOpinionWorkFlowId
          })
            // this.stageDetail.shift()
          this.correctionList = this.stageDetail
        const res1 =[...res.data.response]
        this.stageDetailFirstObj = res1.shift()
        this.stageDetailEndObj = res1.pop()
        }
    },
    async getAcceptanceOpinion(){
      let params = {
        projectId:this.needData.id,
      }
      const res = await getCurrentOpinionList(params)
      if (res.data.success){
        console.log(res.data.response,'response')
        // this.checkTypeList = res.data.response.checkTypeList
        // this.opinionList = res.data.response.opinionList
        let check = res.data.response.checkTypeList
        let opinion = res.data.response.opinionList
        console.log(this.checkTypeList,'checkTypeList')
        console.log(this.opinionList,'opinionList')
        if (opinion){
          let newArray = check.map(item1 => {
            item1.optionValue = 2
            opinion.forEach(item2 => {
              if (item2.mainCheckType===item1.id){
                item1.optionValue =1
              }
            });
            return item1
          });
          this.checkTypeList = newArray
          this.opinionList = opinion

          console.log(newArray,'newArray111');
        }else {
          this.checkTypeList = check
        }
        this.dialog.addListDialog.show = true
      }
      else {
        this.$message({
          message:'无法再次添加！',
          type:"error",
        })
      }
    },
    handleSelect (item) {
      this.filters.assignedUserId = item.id
    },
    querySearchAsync (queryString, cb) {
      getAcceptanceProjectList({  pageSize: 20, pageIndex: 1 }).then(res => {
        if (res.data.success) {
          this.userList = res.data.response.data
          let userList = this.userList
          let results = queryString ? userList.filter(this.createStateFilter(queryString)) : userList
          cb(results)
        }
      })
    },
    createStateFilter (queryString) {
      return (state) => {
        return (state.name.indexOf(queryString) === 0)
      }
    },
    // 日期选择
    changePicker (data) {
      if (data !== null) {
        this.filters.bizStartDate = data[0]
        this.filters.bizEndDate = data[1]
      } else {
        this.filters.bizStartDate = undefined
        this.filters.bizEndDate = undefined
      }
    },
    // 时间格式化
    formatCreateTime: function (row, column) {
      return !row.NoticeTime || row.NoticeTime == ''
          ? ''
          : util.formatDate.format(new Date(row.NoticeTime), 'yyyy-MM-dd hh:mm')
    },
  }
}
</script>

<style lang="stylus" scoped>
  table {
    border-collapse: collapse;
  }

  table, th, td {
    border: 1px solid #DCDFE6;
    padding: 7px;
    text-align: center;
  }
  .highlighted {
    background-color: yellow;  /* 你可以根据需要更改高亮显示的样式 */
  }
  .custom-select {
    /* Add your custom styles for el-select here */
    width: 200px; /* Example: Set a specific width */
    border: 1px solid #ccc; /* Example: Add a border */
  }

  .custom-select .el-select-dropdown {
    /* Add your custom styles for the dropdown here */
    border: 1px solid #ccc; /* Example: Add a border */
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1); /* Example: Add a box shadow */
  }

  .custom-select .el-option {
    /* Add your custom styles for each option here */
    color: #333; /* Example: Change text color */
  }
    #el{

    }
</style>
